import { useState } from 'react';
//components
import Input from '../Components/Common/Input'
import Button from '../Components/Common/Button'
import FormHeader from '../Components/Common/FormHeader'
import FormControl from '../Components/Common/FormControl'
import ErrorMessage from '../Components/Common/ErrorMessage';
import BackgroundEntryPage from '../Components/BackgroundEntryPage.js';
//utils
import {isEmailValid} from '../Utils/isEmailValid';
//services
import { forgotPassword } from '../services/auth'
import { useHistory } from 'react-router-dom';
import Toast from '../Components/Common/Toast.js';
import ErrorAlert from '../Components/Common/ErrorAlert.js';

const ForgetPassword=()=>{
    const router = useHistory()
    const initialState={
        formFields:{
            organizationId : '',
            email : ''
        },
        errorMessage:{
            organizationId : '',
            email : ''
        }
    }
    const [state,setState]=useState(initialState);
    const [isLoading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState({isShow: false, message: ""});
    const onChangeHandler=(event)=>{
        let name = event.target.name
        let value = event.target.value
        let formFields = state.formFields
        let errorMessage = state.errorMessage
        formFields [name] = value
        errorMessage [name] = ''
        setState ( { ...state, formFields, errorMessage } )
    }
    let nextPage=true;
    const onClickHandler=(e)=>{
        e.preventDefault();
        const formFields = Object.keys ( state.formFields ) ;
        let errorMessage = state.errorMessage
         formFields.map ( (key) => {
             if ( !state.formFields [key] ) {
                errorMessage [key] = key.replace(/([a-z0-9])([A-Z])/g, '$1 $2') + ' field should not be empty'
                nextPage=false
             }
             else {
                if( key == 'email' && !isEmailValid ( state.formFields [key] ) ) {
                    errorMessage [key] = 'Please enter valid email'
                    nextPage = false
                } 
             }
         } ) 

        if(nextPage){
            // sent data to server
            const data = {

                mail_id : state.formFields.email,
                user_name : state.formFields.organizationId
            }
            setLoading(true);
            forgotPassword (data).then ( (results) => {
                // all details correct move next page
                setLoading(false);
                setShowToast({isShow: true, message: "Reset password link has been sent your email"});
                setTimeout(()=>{
                    setShowToast({isShow: false, message: ""})
                }, 10000);
            }).catch ( (error) => {
                setLoading(false);
                setShowToast({isShow: true, isError: true, message: "Reset password operation has been failed, try again"});
                setTimeout(()=>{
                    setShowToast({isShow: false, message: ""})
                }, 10000);
                if ( error && error.response && error.response.data && error.response.data.error) {
                    errorMessage.commonError = error.response.data.error;
                }
                else {
                    errorMessage.commonError = `Oops! Something went wrong. 
                    Please try again later. If issue persists, double-check input & retry. For help, contact support.`;
                }
            } )
            
        }
        setState ( { ...state, errorMessage } )
    }

    const onLoginHandler = (e)=>{
        e.preventDefault();
        router.push('/Login')
    }

    const closeToastHandler = () => {
        setShowToast({isShow: false, message: ""})
    }

    const { organizationId, email } = state.formFields
    return (
        <BackgroundEntryPage>
            <FormHeader primaryHeading="Forget Password" subHeading="To recover account"/>
            {!!state.errorMessage.commonError && <ErrorAlert>
                    {state.errorMessage.commonError}
                </ErrorAlert>}
            <form autoComplete='off' onSubmit={onClickHandler}>
                <FormControl>
                    <Input type = "text" value = {organizationId} name = 'organizationId' label = "Organization Id" onChange = {onChangeHandler}/>
                    <ErrorMessage label = { state.errorMessage.organizationId }/>
                </FormControl>
                <FormControl>
                    <Input type = "email" maxLength={254} value = {email} name = 'email' label = "Email" onChange = {onChangeHandler}/>
                    <ErrorMessage label = { state.errorMessage.email }/>
                </FormControl>
                <Button type={'submit'} className = "w-100 btn-primary mt-10 mb-3 btn-sm" label = "Send OTP" disabled={isLoading}>{isLoading ? 'Loading...' : 'Submit'}</Button>
                <a href={'#'} onClick={onLoginHandler}>Login</a>
            </form>
            {showToast.isShow &&<Toast message={showToast.message} isError={showToast.isError} closeToast={closeToastHandler}/>}
        </BackgroundEntryPage>
    )
}
export default ForgetPassword;