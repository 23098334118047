import { Directory, Filesystem, Encoding  } from '@capacitor/filesystem';
import write_blob from "capacitor-blob-writer";
import { Share } from '@capacitor/share';

export const downloadFileOnAndroid = async (data, fileName) =>{

  try {
    await Filesystem.requestPermissions();
    write_blob({
        path: fileName,
        directory: Directory.Documents,
        blob: data,
        on_fallback(error) {
            console.error(error);
        }
    }).then(async function (pathUrl) {
        await Share.share({
          url: pathUrl,
        });
        
    }).catch((error)=>{
      console.log("error write blob");
      console.log(error)
    });
  }
  catch(error) {
    console.log(error)
  }
    
}