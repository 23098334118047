import moment from "moment";
import { useEffect, useState } from "react";

const Counting = (props) => {

  const totalDuration = props.totalDuration;
  const hours = Math.floor(totalDuration/60);
  const minutes = totalDuration > 60 ? Math.floor(totalDuration%60) : Math.floor(totalDuration);
  const duration = moment.duration(("0" + hours).slice(-2)+":"+("0" + minutes).slice(-2)+":00");
  const checkinTimeDate = moment().subtract(duration);
  const [checkinTime, setCheckinTime] = useState(checkinTimeDate);
  const [elapsedTime, setElapsedTime] = useState(0);


  // Function to calculate the elapsed time
  const calculateElapsedTime = () => {
    if (checkinTime) {


      const now = moment();

      const elapsed = now - checkinTime;

      setElapsedTime(elapsed);
    }
  };

  // Update elapsed time every second
  useEffect(() => {
    const timer = setInterval(() => {
      calculateElapsedTime();
    }, 1000);
    // Clean up timer
    return () => clearInterval(timer);
  }, [checkinTime]);

  // Function to format milliseconds to HH:MM:SS
  const formatTime = (milliseconds) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      <div>
        <div className='checkin-spinner-container'><div className="checkin-spinner"></div></div> 
        <p><strong>{formatTime(elapsedTime)}</strong></p>
      </div>
    </div>
  );
}

export default Counting;