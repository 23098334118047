import { useState } from 'react';
import Modal from './Modal';
import Actions from './Actions';
import ModalFooter from './ModalFooter';
import TextArea from './TextArea';
const RemarkPopup = (props)=>{

    const [notes, setNotes] = useState();

    const onChangesHanlder = (e) => {
        setNotes(e.target.value);
    };



    const actions = [  
        {label : "Skip", className : "btn-secondary", onClick : ()=>{props.onClick('')}},
        {label : "Save", className : "btn-primary", onClick : ()=>{props.onClick(notes) }}
    ] ;

    return(
    <>
        <Modal>
            <div className="mb-3">
                <div className=''>
                    <TextArea mandatory={false} maxLength="200" 
                        label = {"Notes"} value = {notes} 
                        onChange ={onChangesHanlder} 
                        name = {'remarks'}
                    />
                    <ModalFooter>
                       <Actions actions = {actions}/>
                    </ModalFooter>
                </div>
            </div>
        </Modal>
    </>
    )
}
export default RemarkPopup;