import Button from '../Components/Common/Button';
// import LoadingModal from '../Components/Common/LoadingModal';
import { useEffect, useState } from "react";
import Layout from '../Components/Layout';
import { getCheckInDetail, updateCheckin } from '../services/attendance';
import moment from 'moment';
import LoadingModal from '../Components/Common/LoadingModal';
import PageHeader from '../Components/Common/PageHeader';
import startCheckin from '../Utils/checkIn';
import Status from '../Components/Common/Status';
import Modal from '../Components/Common/Modal';
import Counting from '../Components/Common/Counting';
import RemarkPopup from '../Components/Common/remaksPopup';


const DashBoard = () => {

    const [checkInDetail, setCheckInDetail] = useState({});
    const [totalDurationInMinutes, setDuration] = useState(0);

    const [isScanning, setScanning] = useState(false);

    const [isShowLoading, setIsLoading] = useState(false);

    const [ textLoading, setTextLoading] = useState('');

    const [ errorMessage, setErrorMessage] = useState('');
    const [isApiLoading, setApiLoading] = useState(true);
    const [isScanningCompleted, setScanningCompleted] = useState(false);
    const [ showRemark, setToShowRemark ] = useState(false);


    useEffect(()=>{
        getCheckInDetail().then((results)=>{
            setApiLoading(false)
            setCheckInDetail(results.data);
            let startTime = "";
            const splitCheckin = [];
            const { entryData } = results.data;
            let totalMinutes = 0;
            if(entryData  && entryData.length) {
                entryData.forEach( (data, index ) => {
                    if(data.checkin_type ===1) {
                        startTime = data.entry_at;
                    }
                    else {                      
                        splitCheckin.push({ checkin: moment(startTime).format("HH:mm"), checkout: moment(data.entry_at).format("HH:mm")});
                        const duration =  moment.duration(moment(data.entry_at, "YYYY-MM-DD HH:mm:ss").diff(moment(startTime, "YYYY-MM-DD HH:mm:ss")));
                        totalMinutes += (duration._data.seconds/60) + duration._data.minutes + (duration._data.hours*60);
                        startTime = "";
                    }
                });
                if(startTime) {
                    const duration =  moment.duration(moment(new Date(),"YYYY-MM-DD HH:mm:ss" ).diff(moment(startTime, "YYYY-MM-DD HH:mm:ss")));
                    splitCheckin.push({ checkin: moment(startTime).format("HH:mm") });
                    totalMinutes += (duration._data.seconds/60) + duration._data.minutes + (duration._data.hours*60);
                    startTime = "";
                }
            }
            setDuration(totalMinutes);
            setErrorMessage('');
        }).catch((er)=> {
            setErrorMessage(er.error)
            setApiLoading(false)
        })
    }, [true])

    const onCheckInHandler = async () => { 
        setScanning(true);
        setIsLoading(true);
        setTextLoading("");
        const employeeCheckInType = checkInDetail.userDetail.checkin_type_id;
        const options = { isCheckGps : checkInDetail.userDetail.gps || false, isRemoteOnly : employeeCheckInType == 3 ? true : false};
        startCheckin(options,(result)=>{
            const {latitude, longitude, address} = result;
            const data = {
                checkInType: employeeCheckInType,
                latitude, longitude, address
            }
            if(employeeCheckInType == 1) {
                data.qrCodeId = result.barcode.displayValue;
            }

            if(checkInDetail.isAllowRemark) {
                setIsLoading(false);
                setToShowRemark(data);
            }
            else {
                setToShowRemark(false);
                updateCheckinHandler(data);
            }
            
            
        }, (errors)=>{
            setScanning(false); 
            if(errors) {
                setErrorMessage(errors);
            }
            else {
                setErrorMessage("There is error check-in, please check with you admin");
            }
            setIsLoading(false);
        }, (isManualClose)=>{
            setScanning(false);
            if(isManualClose) setIsLoading(false);
        });
    };

    const updateCheckinHandler = (data) => {
        updateCheckin(data).then((results)=>{
            setIsLoading(false);
            setScanningCompleted(true);
            setTimeout(()=> {
                setScanning(false);
                setScanningCompleted(false);
            }, 2000);
            const { checkInType, entryData = [] } = results.data;
            const checkInDetailObj = {...checkInDetail};
            const entryDataNew = entryData.length ? entryData : checkInDetailObj.entryData

            let totalMinutes = 0;
            let startTime = "";
            if(entryData  && entryData.length) {
                entryData.forEach( (data, index ) => {
                    if(data.checkin_type ===1) {
                        startTime = data.entry_at;
                    }
                    else {
                        const duration =  moment.duration(moment(data.entry_at, "YYYY-MM-DD HH:mm:ss").diff(moment(startTime, "YYYY-MM-DD HH:mm:ss")));
                        totalMinutes += (duration._data.seconds/60) + duration._data.minutes + (duration._data.hours*60);
                        startTime = "";
                    }
                });
                if(startTime) {
                    const duration =  moment.duration(moment(new Date(),"YYYY-MM-DD HH:mm:ss" ).diff(moment(startTime, "YYYY-MM-DD HH:mm:ss")));
                    totalMinutes += (duration._data.seconds/60) + duration._data.minutes + (duration._data.hours*60);
                    startTime = "";
                }
            }
            setErrorMessage('')
            setCheckInDetail({...checkInDetail, checkInType, entryData: entryDataNew})
            setDuration(totalMinutes)
            setTextLoading(checkInType === 1 ? "Checked out Successfully!" : "Checked In Successfully!")

        }).catch((er) => {
            setScanning(false); 
            setErrorMessage("Not able to CheckIn, please ensure with your admin that your CheckIn type is correct");
            setIsLoading(false);
        })
    }

    const updateRemark = (notes) => {
        const data = {...showRemark};
        data.remarks = notes;
        setIsLoading(true);
        updateCheckinHandler({...data});
        setToShowRemark(false);
    }

   
    const checkInText = !isScanning && checkInDetail.checkInType === 1 ? "CheckIn" : "CheckOut";

    return (
        <>
            { !isScanning && <Layout>
                <PageHeader title="CheckIn"></PageHeader>
                { !isApiLoading && checkInDetail && checkInDetail.userDetail && (  checkInDetail.userDetail.checkin_type_id === 1 || checkInDetail.userDetail.checkin_type_id === 3) ? <div className='mt-60' style={{textAlign:"center"}}>
                    { !isScanning && checkInDetail.checkInType === 2 && totalDurationInMinutes ? <Counting totalDuration={totalDurationInMinutes}/> : null}
                    <Button className = "btn-primary" onClick = {onCheckInHandler} disabled={isScanning}>
                        {isScanning && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                        { checkInDetail.userDetail.checkin_type_id === 1 ? `Scan Branch QR for ${checkInText}` : checkInText}
                        {isScanning && "Loading..."}
                    </Button>
                    {errorMessage ? <div className='mt-60 alert alert-danger d-flex align-items-center'>{errorMessage}</div> : null}
                </div> : !isApiLoading ? <div className='mt-60 alert alert-danger d-flex align-items-center'>Your CheckIn type is QR card, so you cant do checkin/checkout here</div> : <div>Loading...</div>}
               
            </Layout>}
            {
                showRemark && checkInDetail.isAllowRemark && <RemarkPopup onClick={updateRemark} />
            }
            {!isScanning && (isApiLoading || isShowLoading) && <Modal>
                <div className="mb-3">
                    <div className = {'d-block status-spinner'}>
                        <Status spinner = {true} success = {false} successMessage = {""}/>
                    </div>
                </div>
            </Modal>}
            {isScanningCompleted && <LoadingModal loading={isShowLoading} text={textLoading}/>}
        </>
    )
}

export default DashBoard