//https://react-select.com/home
//npm i --save react-select

//demo options
// const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
// ];

import { useState, useRef } from 'react';
import Select from 'react-select';
import FormLabel from './FormLabel';
const MultiSelect = (props)=>{
    const components = {
        DropdownIndicator: null,
    };

    const onSelect = (value, action)=>{
        if(props.onSelect){
            props.onSelect(value, action);
        }
    };

    const onChange = (value, action)=>{
        if(props.onChange){
            props.onChange(value, action)
        }
    };

    const {options = [], value = [], isMulti = false, mandatory = true, placeholder = 'Select', maxMenuHeight = 250, minMenuHeight = 300, dropDownIndicator = true} = props;

    const newOptions = Array.isArray(options) && options.length != 0 && options.map((item)=>{
        return {...item, value : item.id, label : item.name};
    });
    const newValue = Array.isArray(value) && value.length != 0 && value.map((item)=>{
        return {...item, value : item.id, label : item.name};
    });
    return(
        <>
            {props.label && <FormLabel label = {props.label} mandatory = {mandatory}/>}
            <Select 
            components = { !dropDownIndicator && components}
            options = {newOptions}
            value = {newValue}
            // menuIsOpen = {isMenuOpen}
            closeMenuOnSelect = {false}
            isMulti = {true}
            onChange = {onSelect}
            onInputChange = {onChange}
            // onFocus = {onFocus}
            // onBlur = {onBlur}
            placeholder = {placeholder}
            blurInputOnSelect = {false}  
            isSearchable = {true}
            maxMenuHeight = {maxMenuHeight}
            classNamePrefix = 'form-control'
            minMenuHeight = {minMenuHeight}
            backspaceRemovesValue = {false}
            isClearable = {false}
            />
        </>
    )
}

export default MultiSelect;