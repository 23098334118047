import {useState} from 'react';
//components
import Input from '../Components/Common/Input' ;
import Button from '../Components/Common/Button' ;
import FormHeader from '../Components/Common/FormHeader' ;
import FormControl from '../Components/Common/FormControl' ;
import ErrorMessage from '../Components/Common/ErrorMessage' ;
//utils
import {isPasswordValid} from '../Utils/IsPasswordValid.js' ;
import { updatePassword } from '../services/settings.js';
import Toast from '../Components/Common/Toast.js';
import { useHistory, useLocation } from 'react-router-dom';

const ForgetChoosePassword=()=>{
    const initialState={
        formFields:{
            newPassword:"",
            confirmPassword:""
        },
        errorMessage:{
            newPassword:"",
            confirmPassword:""
        }
    }
    const router = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const param = searchParams.get("token");
    const [state,setState]=useState(initialState);
    const [isLoading, setLoading] = useState(false);
    const [isHideForm, setHideForm] = useState(false);
    const [showToast, setShowToast] = useState({isShow: false, message: ""});
    const onChangeHandler=(event)=>{
        const name=event.target.name;
        const value=event.target.value;
        const formFields={...state.formFields,[name]:value}
        const errorMessage={...state.errorMessage,[name]:""}
        if(state.isPasswordNotMatch){
            errorMessage.confirmPassword="";
        }
        setState({...state,formFields,errorMessage})
    }
    const onClickHandler=(e)=>{
        e.preventDefault();
        const formFields=Object.keys(state.formFields);
        const errorMessage={}
        let isPasswordNotMatch=false;
        formFields.forEach((key)=>{
            if(!state.formFields[key]){
                const message= key.replace(/([a-z0-9])([A-Z])/g, '$1 $2')+" field should not be empty";
               errorMessage[key]=message;
               isPasswordNotMatch=false;
            }
            else if(state.formFields.newPassword!=state.formFields.confirmPassword){
                errorMessage["confirmPassword"]="Passwords are not match";
                isPasswordNotMatch=true;
            }
            else if(!isPasswordValid(state.formFields.newPassword)){
                errorMessage["confirmPassword"]="Password must length between 6 to 20"
                isPasswordNotMatch=true;
            }

        });
        setState({...state,errorMessage,isPasswordNotMatch});

        if(!isPasswordNotMatch && Object.keys(errorMessage).length === 0) {
            setLoading(true);
            const data = {
                new_password: state.formFields.newPassword,
                token: param
            }
            updatePassword(data).then( ( results ) => {
                setLoading(false);
                setShowToast({isShow: true, message: "Password has been reset successfully"});
                setHideForm(true);
                setTimeout(()=>{
                    router.push('/Login');
                }, 6000);
            }).catch(()=>{
                setLoading(false);
                setShowToast({isShow: true, isError:true, message: "Password has been reset failed, try again"});
            });
        }

    }
    const closeToastHandler = () => {
        setShowToast({isShow: false, message: ""})
    }
    return (
        <div className='offset-4 col-md-4'>
            {!isHideForm ? <>
                <FormHeader primaryHeading="Choose New Password" subHeading="To recover account"/>
                <form onSubmit={onClickHandler}>
                    <FormControl>
                        <Input type = "Password"  maxLength={20} name = "newPassword" value = {state.formFields.newPassword} label = "New Password" onChange = {onChangeHandler}/>
                        <ErrorMessage label={state.errorMessage.newPassword}/>
                    </FormControl>
                    <FormControl>
                        <Input type = "Password"  maxLength={20} name = "confirmPassword" value = {state.formFields.confirmPassword} label = "Confirm Password" onChange = {onChangeHandler}/>
                        <ErrorMessage label={state.errorMessage.confirmPassword}/>
                    </FormControl>
                    <Button type="submit" className = "btn-primary w-100 mt-1 btn-sm" disabled={isLoading}>{isLoading ? 'Loading...' : 'Change Password'}</Button>
                </form>
            </> : 
            
            <p className='mt-10'>Password has been reset successfully</p>}

            {showToast.isShow &&<Toast message={showToast.message} isError={showToast.isError} closeToast={closeToastHandler}/>}
        </div>
    )  
}
export default ForgetChoosePassword