//components
import Select from './Select';
import Button from './Button';
import Input from './Input';
//utils
import {isValidNumber} from '../../Utils/isValidNumber';
import { useState } from 'react';

const Pagination=(props)=>{
    const {totalItems , itemsPerPage} = props;
    const totalPage = Math.ceil(totalItems/itemsPerPage);
    const pageLinks = [];
    let currentPage = parseInt(props.currentPage) || 1;
    // state for store search page value
    const[page, setPage] = useState() ;
    //corrently showing details
    const startItemLabel = (itemsPerPage * (currentPage - 1)) + 1;
    const endItem = itemsPerPage * currentPage;
    const endItemLabel = endItem > totalItems ? totalItems : endItem; 
    if(totalPage <= 5){
        for(let i = 1; i <= totalPage; i++){
            pageLinks.push(i);
        }
    }
    else{
        if(currentPage<=3){
            for(let i = 1; i <= 4; i++ ){
                pageLinks.push(i);
            };
            pageLinks.push('icon');
            pageLinks.push(totalPage);
        }
        else if(currentPage >= totalPage-2){
            pageLinks.push(1);
            pageLinks.push('icon');
            currentPage = totalPage - 3;
            for(let i = 1; i<=4; i++ ){
                pageLinks.push(currentPage);
                currentPage++;
            }
        }
        else if(currentPage >= 4){
            pageLinks.push(1);
            pageLinks.push('icon');
            currentPage = currentPage-1 ;
            for(let i = 1; i <= 3; i++){
                pageLinks.push(currentPage);
                currentPage++;
            }
            pageLinks.push('icon');
            pageLinks.push(totalPage);
        }
    }

    const routePage =(page)=>{
        if(props.routePage) props.routePage(page);
    };

    const changeItemPerPage =(event)=>{
        if(props.changeItemPerPage) props.changeItemPerPage(event)
    };
    const onChangeSearchValue =(e)=>{
        if(isValidNumber(e.target.value)){
            setPage(e.target.value)
        }
    }
    const searchPage =(no)=>{
        let pageNo = no;
        if(pageNo >= totalPage) pageNo = totalPage;
        if(pageNo <= 1) pageNo = 1; 
        pageNo = parseInt(pageNo);
        if(props.searchPage) props.searchPage(pageNo)
    }

    const onChangeRowsPerPage = (event) => {
        if(props.onChangeRowsPerPage) props.onChangeRowsPerPage(event.target.value);
    }

    const renderPageLinks =()=>{
        return(
            pageLinks.map((link, index)=>{
                let activePage = link == props.currentPage ? 'bg-primary text-light' : '';
                activePage = totalPage == 1 ? 'bg-primary text-light' : activePage;
                return(
                    <>
                    {link == 'icon' && 
                        <li key = {index} className={"page-item disabled"}>
                            <span className={ "page-link"}>...</span>
                        </li> 
                    }
                    {link != 'icon' &&
                        <li key = {index} className={"page-item"}>
                            <span className={ "page-link "+ activePage} onClick = {()=>routePage(link)}>{link}</span>
                        </li>
                    }      
                    </>
                )
            })            
        )

        
    }
    const disablePrev = props.currentPage == 1 ? 'disabled' : '';
    const disableNext = props.currentPage == totalPage ? 'disabled' : '';
    return(
        <div className = 'd-flex justify-content-between pt-2 pb-2 px-2'>
        <div className = 'd-flex text-muted f-s13'>
            <div className = 'd-flex align-items-center mr-5'>
                <span className = 'mr-5'>Items per page :</span>
                <span>
                    <Select value={itemsPerPage} className={"items-per-page-select"} onChange={onChangeRowsPerPage} options = {[{name : 10, value : 10}, {name :50 , value : 50}, {name : 100, value : 100}]}/>
                </span>
            </div>
            <div className = 'd-flex align-items-center'>
                {startItemLabel} - {endItemLabel} of {totalItems} items
            </div>

        </div>
        <div className = 'd-flex'>
            <ul className="pagination f-s14 mr-5">
                <li className={"page-item "+disablePrev}>
                    <span title="Previous" className={ "page-link"} onClick = {()=> routePage('prev')}>
                        <i className="bi bi-caret-left-fill"></i>
                    </span>
                </li>
                {renderPageLinks()}
                <li className={"page-item "+disableNext}>
                    <span title="Next" className={ "page-link "} onClick = {()=> routePage('next')}>
                        <i className="bi bi-caret-right-fill"></i>
                    </span>
                </li>
            </ul>
            {/* <div className = 'd-flex'>
                <Input className = 'pagination-search form-control-sm' onChange = {onChangeSearchValue} value = {page || ''}/>
                <Button className = 'btn-primary btn-sm' onClick = {()=>searchPage(page)}>GO</Button>
            </div> */}
            
        </div>

        </div>
    )

}
export default Pagination