import { useEffect, useState } from "react";
//Componenets
import Layout from "../Components/Layout";
import PageHeader from "../Components/Common/PageHeader";
import Table from '../Components/Common/Table';
import Modal from "../Components/Common/Modal";
import ModalFooter from "../Components/Common/ModalFooter";
import DatePicker from "../Components/Common/DatePicker";
import Input from "../Components/Common/Input";
import FormLabel from "../Components/Common/FormLabel";
import Actions from "../Components/Common/Actions";
import Button from "../Components/Common/Button";
import ErrorMessage from "../Components/Common/ErrorMessage";
import Badge from "../Components/Common/Badge";
import ShowDetails from '../Components/Common/ShowDetails';
import Alert from '../Components/Common/Alert';

const HolidaysManager = (props)=>{

    const [holidayList, setHolidayList] = useState([]);
    const [holidayDetails, setHolidayDetails] = useState({title : '', holidays : []});
    const [errorMessage, setErrorMessage] = useState({});
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowDetails, setIsShowDetails] = useState(false);
    const [modalFor, setModalFor] = useState('');
    const [isShowAlert, setIsShowAlert] = useState(false);
    const demoList = [
        {id : 1, title : 'new Holiday', holidays : [{holidayName : 'diwali', date : 'Nov 3 2021'}, {holidayName : 'diwali', date : 'Nov 3 2021'}, {holidayName : 'diwali', date : 'Nov 3 2021'}]},
        {id : 2, title : 'new Holiday', holidays : [{holidayName : 'diwali', date : 'Nov 3 2021'}, {holidayName : 'diwali', date : 'Nov 3 2021'}, {holidayName : 'diwali', date : 'Nov 3 2021'}]},
        {id : 3, title : 'new Holiday', holidays : [{holidayName : 'diwali', date : 'Nov 3 2021'}, {holidayName : 'diwali', date : 'Nov 3 2021'}, {holidayName : 'diwali', date : 'Nov 3 2021'}]},
        {id : 4, title : 'new Holiday', holidays : [{holidayName : 'diwali', date : 'Nov 3 2021'}, {holidayName : 'diwali', date : 'Nov 3 2021'}, {holidayName : 'diwali', date : 'Nov 3 2021'}]},
        {id : 5, title : 'new Holiday', holidays : [{holidayName : 'diwali', date : 'Nov 3 2021'}, {holidayName : 'diwali', date : 'Nov 3 2021'}, {holidayName : 'diwali', date : 'Nov 3 2021'}]}
    ];

    useEffect(()=>{
        setHolidayList([...demoList]);
    }, [true])

    const showModal = (modal, holidayInfo)=>{
        if(modal == 'create'){
            const date = new Date();
            setHolidayDetails({holidays:[{key : date.getTime()}]});
            setErrorMessage({});
            setIsShowDetails(false);
            setModalFor('create');
        }
        else if(modal == 'edit'){
            setIsShowDetails(false);
            setModalFor('edit');
            let holidays = [];
            holidayInfo.holidays.map((day)=>{
                const holidayDate = new Date(day.date);
                let date = new Date();
                date = date.getTime();
                holidays.push({...day, date : holidayDate, key : date});
            })
            setHolidayDetails({...holidayInfo, holidays : holidays});
        }
        else  {
            setModalFor('show');
            setHolidayDetails({...holidayInfo})
            setIsShowDetails(true);
        }
        setIsShowModal(true);
    };

    const hideModal = ()=>{
        setIsShowModal(false);
        setHolidayDetails({});
        setErrorMessage({});
    };

    const addRow = ()=>{
        const date = new Date();
        const newHolidays = [...holidayDetails.holidays];
        newHolidays.push({key : date.getTime()});
        setHolidayDetails({...holidayDetails, holidays : newHolidays});
    };

    const deleteRow = (key)=>{
        const newHolidays = [...holidayDetails.holidays];
        const index = newHolidays.findIndex((detail)=> detail.key == key);
        newHolidays.splice(index, 1);
        setHolidayDetails({...holidayDetails, holidays : newHolidays});
    };

    const dateOnChange = (date, key)=>{
        const newHolidays = [...holidayDetails.holidays];
        const index = newHolidays .findIndex((detail)=> detail.key == key );
        newHolidays[index] = {...newHolidays[index], date : date};
        setHolidayDetails({...holidayDetails, holidays : newHolidays});
        const newErrorMessage = {...errorMessage};
        newErrorMessage[key + 'date'] = '';
        setErrorMessage({...newErrorMessage});
    };
     
    const holidayNameOnChange = (event, key)=>{
        const newHolidays = [...holidayDetails.holidays];
        const index = newHolidays.findIndex((detail)=>detail.key == key);
        newHolidays[index] = {...newHolidays[index], holidayName : event.target.value};
        setHolidayDetails({...holidayDetails, holidays : newHolidays});
        const newErrorMessage = {...errorMessage};
        newErrorMessage[key + 'holidayName'] = '';
        setErrorMessage({...newErrorMessage});
    };

    const titleOnChange = (event)=>{
        const newHolidaysDetails = {...holidayDetails};
        newHolidaysDetails.title = event.target.value;
        setHolidayDetails({...newHolidaysDetails});
        const newErrorMessage = {...errorMessage};
        newErrorMessage.title = '';
        setErrorMessage({...newErrorMessage});
    };
    const validateInputFields = ()=>{
        const newErrorMessage = {};
        let isValid = true;
        if(!holidayDetails.title){
            newErrorMessage.title = 'Title field should be empty';
            isValid = false;
        }
        holidayDetails.holidays.map((detail)=>{
            if( !detail.date){
                newErrorMessage[detail.key + 'date'] = 'Date field should be empty';
                isValid = false;
            }
            if( !detail.holidayName){
                newErrorMessage[detail.key + 'holidayName'] = 'Holiday Name field should be empty';
                isValid = false;
            }
        })
        setErrorMessage({...newErrorMessage});
        return isValid;
    };

    const createHoliday = ()=>{
        const isCreateHoliday =  validateInputFields();
        console.log(isCreateHoliday)
        if(isCreateHoliday){
            const newHolidayList = [...holidayList];
            newHolidayList.push({...holidayDetails});
            setHolidayList([...newHolidayList]);
            hideModal();
        }
    };
    const updateHoliday = ()=>{
        const isUpdateHoliday =  validateInputFields();
        if(isUpdateHoliday){
            let newHolidayList = [...holidayList];
            const updatedIndex = holidayList.findIndex((list)=> list.id == holidayDetails.id);
            newHolidayList[updatedIndex] = {...holidayDetails};
            setHolidayList([...newHolidayList]);
        }
    };
    const showAlert =(holidayInfo)=>{
        setHolidayDetails({...holidayInfo});
        setIsShowAlert(true);
    };

    const hideAlert = ()=>{
        setIsShowAlert(false);
    };

    const deleteHoliday = ()=>{
        const newHolidayList = [...holidayList];
        const index = newHolidayList.findIndex((detail)=>detail.id == holidayDetails.id);
        newHolidayList.splice(index, 1);
        setHolidayList([...newHolidayList]);
        hideAlert();
    };
    const header = [
        { key : 'title'},
        { label : 'Actions', key : 'action',
        actions : [
            {
                label : <i className="bi bi-pencil-fill"></i>,
                key : "edit",
                className : 'btn-outline-primary',
                onClick : (holidayInfo)=> showModal('edit', holidayInfo)
            },
            { 
                className : 'btn-outline-success  ml-10', 
                label : <i className = "bi bi-card-text"></i>,
                title : 'View Holiday Details',
                onClick : (holidayInfo) => showModal('show', holidayInfo)
            },
            {
            label : <i className = "bi bi-trash-fill"></i>,
            key : "delete",
            className : 'btn-outline-danger ml-10',
            title : 'Delete Holiday',
            onClick : (holidayInfo) => showAlert(holidayInfo )
            }
        ]
        }
    ];
    const createActions = [
        {label : 'Close', className : 'btn-secondary', onClick : hideModal},
        {label : 'Save', className : 'btn-primary', onClick : createHoliday}
    ];
    const editActions = [
        {label : 'Close', className : 'btn-secondary', onClick : hideModal},
        {label : 'Update', className : 'btn-primary', onClick : updateHoliday}
    ];
    const showActions = [
        {label : 'Close', className : 'btn-secondary', onClick : hideModal}
    ];

    let actions = [];
    actions = modalFor == 'create'? createActions : actions;
    actions = modalFor == 'edit'? createActions : actions;
    actions = modalFor == 'show'? createActions : actions;
    let title = isShowDetails? 'Holiday Details' : 'Create Holiday';

    if(modalFor == 'create'){
        actions = createActions;
        title = 'Create Holiday';
    }else if(modalFor == 'edit'){
        actions = editActions;
        title = 'Edit Holiday';
    }else if(modalFor == 'show'){
        actions = showActions;
        title = 'Holiday Details';
    }
    const deleteActions = [
        {label : 'Close', className : 'btn-secondary', onClick : hideAlert},
        {label : 'Delete', className : 'btn-danger', onClick : deleteHoliday }
    ];

    //render
    const renderInputFields = ()=>{
        return(
            <>
                <Input label = 'Title' onChange = {titleOnChange} value = {holidayDetails.title}/>
                <ErrorMessage label = {errorMessage.title}/>
                <div className = 'row mt-2'>
                    <div className = 'col'>
                        <FormLabel mandatory = {false} label = 'Holiday Name'/>
                    </div>
                    <div className = 'col-4'>
                        <FormLabel mandatory = {false} label = 'Date' />
                    </div>
                    <div className = 'col-2'>
                        <FormLabel mandatory = {false} label = 'Action'/>
                    </div>
                </div>
                {holidayDetails.holidays.map((detail, index)=>{
                    return(
                    <div key = {detail.key} className = 'row mb-2'>
                        <div className = 'col'>
                            <Input onChange = {(event)=>holidayNameOnChange(event, detail.key)} value = {detail.holidayName}/>
                            <ErrorMessage label = {errorMessage[detail.key + 'holidayName']}/>
                        </div>
                        <div className = 'col-4'>
                            <DatePicker selected = {detail.date} onChange = {(date)=>dateOnChange(date, detail.key)}/>
                            <ErrorMessage label = {errorMessage[detail.key + 'date']}/>
                        </div>
                        <div className = 'col-2'>
                            {holidayDetails.holidays.length != 1 &&
                            <Button className = 'btn-danger btn-sm' onClick = {()=>deleteRow(detail.key)}><i className = 'bi bi-x-circle'></i></Button>
                            }
                            {holidayDetails.holidays.length-1 == index &&
                            <Button className = 'btn-primary btn-sm ml-4' onClick = {addRow}><i className = 'bi bi-plus-circle'></i></Button>
                            }
                        </div>
                    </div>
                    )

                })}
            </>
        )
    };
    const renderHolidayDetails = ()=>{
        return(
            <>
                <ShowDetails col = {[{row:[{key : 'title'}]}]} value = {holidayDetails}/>
                <div className = 'row mt-2'>
                    <div className = 'col-4'>
                        <FormLabel mandatory = {false} label = 'Date' />
                    </div>
                    <div className = 'col'>
                        <FormLabel mandatory = {false} label = 'Holiday Name'/>
                    </div>
                </div>
                {holidayDetails.holidays.map((detail, index)=>{
                    return(
                        <>
                        <div key = {index} className = 'row mb-2'>
                            <div className = 'col-4'>
                                <Badge className = 'bg-success f-s16'>{detail.date}</Badge>
                            </div>
                            <div className = 'col'>
                                <Badge className = 'bg-primary f-s16'>{detail.holidayName}</Badge>
                            </div>
                        </div>
                        </>
                    )
                })}

            </>
        )
    }
    //render *--*
    return(
        <Layout>
            <PageHeader title = 'Holidays Management' label = 'Create Holiday' onClick = {()=>showModal('create')}/>
            <Table header = {header} body = {holidayList}/>
            {isShowModal && 
                <Modal title = {title} hideModal = {hideModal} className = 'modal-lg'>
                    <div className = 'modal-content-custom'>
                        {(modalFor == 'create' || modalFor == 'edit') &&
                            <>
                                {renderInputFields()}
                            </>
                        }
                        { modalFor == 'show' && 
                            <>
                                {renderHolidayDetails()}
                            </>
                        }
                    </div>
                    <ModalFooter>
                        <Actions actions = {actions}/>
                    </ModalFooter>
                </Modal>            
            }
            {isShowAlert && 
                <Alert label = 'Are sure delete this holiday?' hide = {hideAlert} actions = {deleteActions}/>
            }
        </Layout>
    )
}
export default HolidaysManager;