import { useContext, useEffect, useState } from 'react';
import PageHeader from '../Components/Common/PageHeader'
import Layout from '../Components/Layout'
import { getActivity } from '../services/dashboard';
import ReactPlayer from 'react-player/youtube'
import { useHistory } from 'react-router-dom';
import AddBranch from '../Components/AddBranch';
import Toast from '../Components/Common/Toast';
import Table from '../Components/Common/Table';
import { getSingleDayAttendance } from '../services/attendance';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ExportToExcel from '../Components/ExcelSheet';
import { userDetailsContext } from '../context';
import ErrorAlert from '../Components/Common/ErrorAlert';
import Modal from '../Components/Common/Modal';
import Status from '../Components/Common/Status';
const DashBoard = (props) => {

    const history = useHistory();
    const [activities, setActivities] = useState([]);
    const [ isShowBranchModal, setIsShowBranchModal ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ isSetupNotCompleted, setSetupCompleted] = useState(-1);
    const [showToast, setShowToast] = useState({});
    const [paginationDetail, setPaginationDetail] = useState({itemsPerPage:10, page:1, total:10});
    const [ attendance, setAttendace ] = useState([]);
    const [attendanceDate, setAttendanceDate] = useState(new Date());
    const [totalCheckinCount, setTotalCheckins] = useState(0);
    const roleName = useContext(userDetailsContext).role;
    const menu = useContext(userDetailsContext).menu;
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if(!isShowBranchModal) {
            getActivity().then((results) => {
                const {data:{result}} = results;
                setActivities(result);
                const setupNotCompleted = result.some(item => {
                    return !item.iscompleted 
                });
                setSetupCompleted(setupNotCompleted ? 1 : 0);
            }).catch((error) => {
                if(error && error.response && error.response.status === 400) {
                    setSetupCompleted(0);
                }
                else {
                    setErrorMessage(`Oops! Something went wrong. 
                    Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
                }
            })
        }
    },[isShowBranchModal]);

    const checkInTypes = {3:{ name:"Direct",value:1}, 2:{name:"Employee QR",value:2}, 1:{name:"Branch QR",value:3}};

    const checkInTypesByCode = {REMOT:{ name:"Direct",value:1}, CARD:{name:"Employee QR",value:2}, FIXED:{name:"Branch QR",value:3}};
    


    const hideErrorMessage = () => {
        setErrorMessage('')
    }


    useEffect(()=>{
        if(roleName === 'employee') {
            if(menu.includes('checkin')) {
                history.push('/Checkin');
            }
            else {
                history.push('/Attendance');
            }
        }
        else {
            const isRestrictPage = localStorage.getItem ( 'isRestrictPage' );
            if(isRestrictPage === 'true') history.push('/CheckinAdmin');
        }
    }, [roleName])

    useEffect(() => {
        
        const selectedDate = moment(attendanceDate).format("YYYY_MM_DD");
        getSingleDayAttendance({
            date:selectedDate
        }).then((results) => {
            const attendanceList = [];
            const { result=[], itemsPerPage=10, page=1, total=10, totalCheckins} = results.data;
            result.map((data)=> {
                let checkin = '';
                let checkTypeName = '';
                if(data.attendance && data.attendance.length) {
                    checkin = moment(data.attendance[0].entry_at).format("hh:mm:ss a");
                    checkTypeName = checkInTypesByCode[data.attendance[0].checkin_entry_type_code].name
                }

                attendanceList.push({...data, name : data.first_name+ ' ' + data.last_name, checkin, checkTypeName});
            });
            setTotalCheckins(totalCheckins);
            setPaginationDetail({itemsPerPage, page, total});
            setAttendace(attendanceList);
        }).catch(()=>{
            setErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`)
        })
    },[attendanceDate])


    const closeBranchModal = () => {
        setIsShowBranchModal(false);
        setIsLoading ( false );
    }

    const addBranchHandler = (data) => {
        setIsShowBranchModal(false);
        setIsLoading ( false );
        setShowToast({isShow: true, message: "Created Branch Successfully!"});
        setTimeout(()=>{
            setShowToast({isShow: false, message: ""})
        }, 2000);
    }

    const onClickTodo = (e, activityName) => {
        e.preventDefault();
        if(activityName === 'Add new branch') {
            setIsShowBranchModal(true);
        }
        else {
            history.push('/AddEmployee');
        }
    }

    const closeToastHandler = () => {
        setShowToast({isShow: false, message: ""})
    }

    const header = [
        { key : 'id', label : 'Id'},
        { key : 'name', label : 'Name'},
        { key : 'checkin', label : 'CheckedIn'},
        {key: 'checkTypeName', label: 'Checkin Type'}
    ];

    const onPaginationRoutePage = (page) => {
        const selectedDate = moment(attendanceDate).format("YYYY_MM_DD");
        const updatePagination = { ...paginationDetail, page, date:selectedDate};
        setPaginationDetail(updatePagination);
        getSingleDayAttendance(updatePagination).then((results) => {
            const attendanceList = [];
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            result.map((data)=> {
                let checkin = '';
                let checkTypeName = '';
                if(data.attendance && data.attendance.length) {
                    checkin = moment(data.attendance[0].entry_at).format("hh:mm:ss a");
                    checkTypeName = checkInTypesByCode[data.attendance[0].checkin_entry_type_code].name
                }

                attendanceList.push({...data, name : data.first_name+ ' ' + data.last_name, checkin, checkTypeName});
            });
            setPaginationDetail({itemsPerPage, page, total});
            setAttendace(attendanceList);
        }).catch(()=>{
            setErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`)
        });
    }

    const onChangeRowsPerPage = (itemsPerPage) => {
        const selectedDate = moment(attendanceDate).format("YYYY_MM_DD");
        const updatePagination = { ...paginationDetail, itemsPerPage, page:1, date:selectedDate};
        setPaginationDetail(updatePagination);
        getSingleDayAttendance(updatePagination).then((results) => {
            const attendanceList = [];
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            result.map((data)=> {
                let checkin = '';
                let checkTypeName = '';
                if(data.attendance && data.attendance.length) {
                    checkin = moment(data.attendance[0].entry_at).format("hh:mm:ss a");
                    checkTypeName = checkInTypesByCode[data.attendance[0].checkin_entry_type_code].name
                }

                attendanceList.push({...data, name : data.first_name+ ' ' + data.last_name, checkin, checkTypeName});
            });
            setPaginationDetail({itemsPerPage, page, total});
            setAttendace(attendanceList);
        }).catch(()=>{
            setErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`)
        })
    }

    const onChangeDate = (date) => {
        setAttendanceDate(date);
     }

    return (
        <>
            <Layout>
               <PageHeader title="Dashboard"></PageHeader>
               {!!errorMessage && <ErrorAlert close={hideErrorMessage}>
                {errorMessage}
               </ErrorAlert>}
               {isSetupNotCompleted === 1 ? <div className='container row'>
                    <div className='col to-do-main'>                        
                        <div className="card to-do-wrapper">
                                <div className="card-body"> 
                                    <h5 className="card-title">Welcome to TheCheckIn!</h5>
                                    <p>To get started, here are a few things you might want to do...</p>                     
                                    <ul className="list-group">
                                        {activities.map((item)=> {
                                            if(item.step > 0 && item.step < 4) {
                                                const className = item.iscompleted ? 'bi bi-check-circle-fill text-success me-2' : 'i bi-circle me-2';
                                                return <li className="list-group-item">
                                                    <i className={className}></i>
                                                    {item.iscompleted ? item.activity : <a href='#' onClick={(e)=>{onClickTodo(e, item.activity)}}>{item.activity}</a>}
                                                </li>
                                            }
                                            else {
                                                return null;
                                            }
                                        }
                                        )}
                                    </ul>
                                </div>
                        </div>
                    </div>
                    { activities[3] && activities[3].activity && <div className='col'>
                        <div className='card youtube-video'>
                            <div className='card-body'>
                                <div className=''>
                                    {/* <p>Get Started with TheCheckIn in 58 Seconds!</p> */}
                                    <p className="">Learn How to Set Up & Use TheCheckIn in Just 58 Seconds! 🕒✨ <a href={activities[3].activity} target='_blank'>Click here</a></p>
                                    <ReactPlayer width="280px" height="160px" url={activities[3].activity} />
                                </div>
                            </div>
                        </div>
                    </div>}
               </div>
               : isSetupNotCompleted == 0 ? <>
                    <div className='container row'>
                        <div className='col dashboard-tile-wrap'>
                            <div className='dashboard-tiles'>
                                <p>Total</p>
                                <strong>{attendance.length}</strong>
                            </div>
                        </div>
                        <div className='col dashboard-tile-wrap'>
                            <div className='dashboard-tiles'>
                                <p>Checkins</p>
                                <strong>{totalCheckinCount}</strong>
                            </div>
                        </div>
                        <div className='col dashboard-tile-wrap dashboard-date-wrap'>
                            <div className='dashboard-tiles'>
                                <DatePicker maxDate={moment().toDate()} id="startDate" className="dashboard-date-picker" selected={attendanceDate} onChange={onChangeDate}/>
                                {!!attendance.length && <ExportToExcel label="Download" excelData={attendance} fileName={"Attendance_"} exportKeys={[ {key: "id", label:"Employee Id"},{ key:"name", label:"Employee Name"}, { key: "checkin"} , {key:"checkTypeName"}]} />}
                            </div>
                        </div>
                    </div>
                    <Table isLoader={isLoading} header = {header} 
                        body = {attendance} 
                        paginationDetail={paginationDetail} 
                        routePage={onPaginationRoutePage} 
                        onChangeRowsPerPage={onChangeRowsPerPage} 
                    />
               </> : <Modal>
                    <div className="mb-3">
                        <div className = {'d-block status-spinner'}>
                            <Status spinner = {true} success = {false} successMessage = {''}/>
                        </div>
                    </div>
                </Modal>}
               {showToast.isShow &&<Toast message={showToast.message} closeToast={closeToastHandler}/>}
               {isShowBranchModal && <AddBranch closeModal={closeBranchModal} addBranch={addBranchHandler} />}
            </Layout>
        </>
    )
}

export default DashBoard