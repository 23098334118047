import Button from './Button'
const Actions = (props) =>{
    return(
        <>
        {props.actions && props.actions.length && props.actions.map((action)=>{
            return  <Button key = {action.label} className = {'me-1 btn-sm '+action.className} onClick = {action.onClick}>
                        {action.label}
                    </Button>
        }) }
        </>
    )
}
export default Actions