import axios from "axios"
const BaseUrl = process.env.REACT_APP_BASEURL;


export const getActivity = () => {
    const url = BaseUrl+'activity' 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'get',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        }
 
    } )
 }