import Button from '../Common/Button';
import { mobileMenuHandler } from '../../context';
import { useContext } from 'react';

const PageHeader = (props) => {
    const { title, label, onClick } = props;
    const {showNavBar} = useContext(mobileMenuHandler);
    
    return (
        <>
            <div className = {`d-flex align-items-center px-3 navbar border-bottom ${label ? 'justify-content-between' : 'justify-content-start'}`}>
                <i class="bi bi-list show-nav-bar-icon d-lg-none" onClick={showNavBar}></i>
                <span className = 'page-title'>{title}</span>
                
                {label && 
                    <Button  onClick = {onClick} className = 'btn-primary f-s14 f-w-600 btn-sm'>{label}</Button>
                }
                
            </div>
        </>
    )
}

export default PageHeader