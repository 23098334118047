const Button =(props)=>{
    const { className="", type="button", title, disabled=false} = props

        const onClickHandler=(e)=>{
            if(props.onClick){
                props.onClick(e)
            }
        }

        return(
            <button type={type} className = {"btn "+className} title={title} disabled={disabled} onClick={onClickHandler}>
              {/* {props.icon && <span className = 'mr-5'><i className = {props.icon}></i></span>}{label} */}
              {props.children}
            </button>
        )
}
export default Button