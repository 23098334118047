import React, { useState } from "react";
import DatePickerReact from "react-datepicker";
import { useRef, forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";

//https://reactdatepicker.com/#example-custom-header
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DatePicker = (props) => {
  const [startDate, setStartDate, minDate] = useState(new Date());
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <div className = 'date-picker'>
        <input className = 'form-control' onClick={onClick} ref={ref} value = {value} readOnly/>
        <i className = "bi bi-calendar"></i>
      </div>
    </>
  ));

  const onChangeHandler = (date)=>{
    setStartDate(date);
    if(props.onChange){
      props.onChange(date);
    }
  };
  

  

  return (
    <>
    <DatePickerReact 
        minDate = {minDate}
        selected = {props.selected}
        onChange = {onChangeHandler}
    />
    </>
  );
};

export default DatePicker;