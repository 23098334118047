import { useContext, useEffect, useState } from "react";
//componenets
import Layout from "../Components/Layout";
import PageHeader from '../Components/Common/PageHeader';
import Table from "../Components/Common/Table";
import Modal from "../Components/Common/Modal";
import Form from "../Components/Common/Form";
import Status from "../Components/Common/Status";
import ModalFooter from "../Components/Common/ModalFooter";
import Actions from "../Components/Common/Actions";
import Alert from "../Components/Common/Alert";
import { userDetailsContext } from "../context";
//utils
import {validation} from '../Utils/validation';
//services
import {getLeavesList, createLeave as createLeaveService ,updateLeave as updateLeaveService, deleteLeave as deleteLeaveService} from '../services/leave';

const Leave = (props)=> {

    // state for get leave list from server
    const [leaveList, setLeaveList] = useState([]);
    //state for create and update new leave
    const [leave, setLeave] = useState({})
    //state for errorMessage (create and update leave)
    const [errorMessage, setErrorMessage] = useState({})
    //state for show and hide the modal
    const [isShowModal, setIsShowModal] = useState(false);
    // state for change modal add or edit
    const [addEdit, setAddEdit] = useState() ;
    // state for show the create and update (leave) status (modal)
    const [status, setStatus] = useState({showStatus : false}) ;
    // state for show & hide & setMessage the alert 
    const [alert, setAlert] = useState({isShowAlert : false}) ;
    const [isLoader, setLoader] = useState(false);

    const [paginationDetail, setPaginationDetail] = useState({itemsPerPage:10, page:1, total:10});

    const roleName = useContext(userDetailsContext).role;

    useEffect(()=>{
        setLoader(true);
        getLeavesList().then((results)=>{
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            const newLeaveList = [];
            result.map((leaveDetails)=>{
                newLeaveList.push({...leaveDetails, leaveName : leaveDetails.name, leaveCount : leaveDetails.leave_count})
            })
            setLoader(false);
            setLeaveList([...newLeaveList]);            
            setPaginationDetail({itemsPerPage, page, total});
        }).catch(() => {
            setLoader(false);
        })
    }, [true])

    //onchange handler for overall page
    const onChangeHandler = (event, key)=> {
        const newLeave = {...leave} ;
        const newErrorMessage = {...errorMessage} ;
        newLeave[key] = event.target.value ;
        newErrorMessage[key] = '' ;
        setLeave({...newLeave}) ;
        setErrorMessage({...newErrorMessage})
    }

    const showModal = (addOrEdit, leaveInfo)=>{
        if(addOrEdit == 'addLeave'){
            setLeave({});
            setAddEdit(addOrEdit);
        }
        else{
            setLeave({...leaveInfo});
            setAddEdit(addOrEdit);
        }
        setIsShowModal(true) ;
    };

    const hideModal = ()=> {
        setIsShowModal(false) ;
        setStatus({showStatus : false}) ;
        setErrorMessage({}) ;
        setLeave({}) ;
    };
    // validate all input field for create and update leave details
    const validateInputFields =()=>{
        const validationResult = validation(inputFields, leave);
        setErrorMessage({...validationResult.errorMessage});
        return {isValid : validationResult.isValid};
    };

    const createLeave = ()=> {
        const validationResult = validateInputFields();
        const isCreateLeave = validationResult.isValid;
        if(isCreateLeave){
            setStatus({showStatus : true, spinner : true});
            const {leaveName, leaveCount} = leave;
            const data = {name : leaveName, leave_count : leaveCount};
            createLeaveService(data).then((results)=>{
                // insert created row in table(leave List)
                const newLeaveList = [...leaveList];
                newLeaveList.push({id : results.data.insertId, ...leave});
                setLeaveList([...newLeaveList]);
                // show leave create status
                setStatus({showStatus : true, spinner : false, success : true, successMessage : 'Leave created succesfully'})

            }).catch() ;
        }
    }

    const updateLeave = ()=>{

        const validationResult = validateInputFields();
        const isUpdateLeave = validationResult.isValid ;
 
         if(isUpdateLeave){
             setStatus({showStatus : true, spinner : true}) ;
             const {leaveName, leaveCount, id} = leave;
             const data = { id : id, name : leaveName, leave_count : leaveCount};
             updateLeaveService(data).then((results)=>{
                 console.log(results)
                 // insert updated row in table(leave List)
                const newLeaveList = [...leaveList];
                const updatedIndex = newLeaveList.findIndex((leaveDetails)=> leaveDetails.id == data.id) ;
                newLeaveList[updatedIndex] = {...leave};
                setLeaveList([...newLeaveList]);
                 // show leave create status
                 setStatus({showStatus : true, spinner : false, success : true, successMessage : 'Leave updated succesfully'})
             }).catch() ;
         }
    }

    const showAlert = (leaveInfo)=> {
        setLeave({ id : leaveInfo.id }) ;
        setAlert({isShowAlert : true, message : 'Are you sure delete this leave ?'}) ;
    }

    const hideAlert =()=> {
        setAlert({isShowAlert : false}) ;
        setLeave({}) ;
    };

    const deleteLeave =()=>{
        const id = leave.id;
        deleteLeaveService(id).then((results)=> {
            const newLeaveList = [...leaveList];
            const deleteIndex = newLeaveList.findIndex((leaveDetails)=> leaveDetails.id == id);
            newLeaveList.splice(deleteIndex, 1);
            setLeaveList([...newLeaveList]);
            hideAlert();
        }).catch((err)=>{

        })
    };

    const onPaginationRoutePage = (page) => {
        const updatePagination = { ...paginationDetail, page};
        setPaginationDetail(updatePagination);
        getLeavesList(updatePagination).then((results)=>{
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            const newLeaveList = [];
            result.map((leaveDetails)=>{
                newLeaveList.push({...leaveDetails, leaveName : leaveDetails.name, leaveCount : leaveDetails.leave_count})
            })
            setLeaveList([...newLeaveList]);
        }).catch()
    }

    const onChangeRowsPerPage = (itemsPerPage) => {
        const updatePagination = { ...paginationDetail, itemsPerPage, page:1};
        setPaginationDetail(updatePagination);
        getLeavesList(updatePagination).then((results)=>{
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            const newLeaveList = [];
            result.map((leaveDetails)=>{
                newLeaveList.push({...leaveDetails, leaveName : leaveDetails.name, leaveCount : leaveDetails.leave_count})
            })
            setLeaveList([...newLeaveList]);
        }).catch()
    }

    const actionsBtn = { 
        key : "action", label : "Actions",
        actions:[
            {
            label : <i className="bi bi-pencil-fill"></i>,
            key : "edit",
            className : 'btn-outline-primary',
            onClick : (leaveInfo) => showModal ( 'editLeave', leaveInfo )
            },
            {
            label : <i className="bi bi-trash-fill"></i>,
            key : "delete",
            className : 'btn-outline-danger ml-10',
            onClick : (leaveInfo) => showAlert ( leaveInfo )
            }
        ]
    }

    const header = [
        {  key : 'leaveName'},
        { key : 'leaveCount'}
    ];

    if(roleName === 'admin') header.push(actionsBtn);

    // input fields for add and edit leave (modal)
    const inputFields = [
        {row : [{type : 'text', key : 'leaveName'}]},
        {row : [{type : 'text', key : 'leaveCount', validationType : 'number'}]},
    ];
    //actions for add and edit leave (modal)
    const addLeaveActions = [  
        {label : "Close", className : "btn-secondary", onClick : hideModal},
        {label : "Save", className : "btn-primary", onClick : createLeave }
    ];

    const editLeaveActions = [ 
        {label : "Close", className : "btn-secondary", onClick : hideModal },
        {label : "Update", className : "btn-primary", onClick : updateLeave }
    ];
    const actions = addEdit == 'addLeave' ? addLeaveActions : editLeaveActions;
    // change modal add or edit
    const modalTitle = addEdit == 'addLeave' ? 'Create New Leave' : 'Edit Leave' ;
    // actions for delete leave from list 
    const deleteActions = [
        {label : 'Close', className : 'btn-secondary', onClick : hideAlert},
        {label : 'Delete', className : 'btn-danger', onClick : deleteLeave}
    ];

    return(
        <Layout>
            { (roleName === "admin" || roleName === "branchLead") && <PageHeader title = 'Leave Management' label = 'Add Leave' onClick = {()=> showModal('addLeave')}/> }
            { (roleName === 'employee' || roleName === "teamLead") && <PageHeader title = 'Leave Management' /> }
            <Table isLoader={isLoader} header = {header} body = {leaveList} paginationDetail={paginationDetail} routePage={onPaginationRoutePage} onChangeRowsPerPage={onChangeRowsPerPage} />
            {isShowModal && 
                <Modal title = {modalTitle} hideModal = {hideModal}>
                    <div className = {!status.showStatus? 'd-block' : 'd-none'}>
                        <Form rows = {inputFields} value = {leave} errorMessage = {errorMessage} onChange = {onChangeHandler}/>
                        <ModalFooter>
                            <Actions actions = {actions}/>
                        </ModalFooter>
                    </div>
                    <div className = {status.showStatus? 'd-block mb-3' : 'd-none'}>
                        <Status spinner = {status.spinner} success = {status.success} successMessage = {status.successMessage} onClick = {hideModal}/>
                    </div>
                </Modal>
            }
            {alert.isShowAlert && 
                <Alert label = {alert.message} actions = {deleteActions} hide = {hideAlert}/>
            }
        </Layout>
    )
    
}
export default Leave