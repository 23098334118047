import Button from '../Components/Common/Button';
import LoadingModal from '../Components/Common/LoadingModal';
import { useEffect, useState } from "react";
import Layout from '../Components/Layout';
import { updateCheckin } from '../services/attendance';
import PageHeader from '../Components/Common/PageHeader';
import startCheckin from '../Utils/checkIn';
import { getUserDetails } from '../services/settings';
import RemarkPopup from '../Components/Common/remaksPopup';
import Status from '../Components/Common/Status';
import Modal from '../Components/Common/Modal';

const CheckInAdmin = () => {

    const [isScanning, setScanning] = useState(false);

    const [isShowLoading, setIsLoading] = useState(false);

    const [ textLoading, setTextLoading] = useState('');

    const [ errorMessage, setErrorMessage] = useState('');

    const [ userDetail, setUserDetail ] = useState({});

    const [isScanningCompleted, setScanningCompleted] = useState(false);

    const [ showRemark, setToShowRemark ] = useState(false);


    useEffect(()=>{
        getUserDetails().then ( ( results ) => {
            const response = results.data;
            const data = {
                name: `${response.first_name} ${response.last_name}`,
                timezone: response.timezone,
                mailId: response.mail_id,
                companyId: response.company_id,
                customName: response.custom_name,
                customCompanyName:'',
                isAllowRemark : response.is_allow_remark
            }
            setUserDetail(data);
        }).catch((err)=>{
            //setLoader(false);
            setErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        });
    }, [])

    
    const startScan = async () => { 
        setScanning(true);
        setTextLoading("");
        setIsLoading(true);
        const options = {isCheckGps:true};
        startCheckin(options,(result)=>{
            const {latitude, longitude, address} = result;
            const data = {
                qrCodeId:result.barcode.displayValue,
                checkInType: 2,
                latitude, longitude, address
            }  
            if(userDetail.isAllowRemark) {
                setIsLoading(false);
                setToShowRemark(data);
            }
            else {
                
                setToShowRemark(false);
                updateCheckinHandler(data);
            }
        }, (errors)=>{
            setScanning(false); 
            if(errors) {
                setErrorMessage(errors);
            }
            else {
                setErrorMessage("There is error while scanning, please check with your admin");
            }
            setIsLoading(false);
        }, (isManualClose)=>{
            setScanning(false);
            if(isManualClose) setIsLoading(false);
        });
    };

    const onClickRemark = (notes) => {
        const data = {...showRemark};
        data.remarks = notes;
        setIsLoading(true);
        updateCheckinHandler({...data});
        setToShowRemark(false);
    }

    const updateCheckinHandler = (data) => {
        updateCheckin(data).then((results)=>{
            setIsLoading(false);
            setScanningCompleted(true);
            setTimeout(()=> {
                setScanning(false);
                setScanningCompleted(false);
            }, 2000);
            const { checkInType, entryData = [] } = results.data;
            setErrorMessage('');
            setTextLoading(checkInType === 1 ? "Checked out Successfully!" : "Checked In Successfully!")

        }).catch((er) => {
            setScanning(false); 
            setErrorMessage("Not able to CheckIn, please ensure with your admin that your CheckIn type is Employee QR");
            setIsLoading(false);
        })
    }

   

    return (
        <>
            { !isScanning && <Layout>
                <PageHeader title="Scan QR Kiosk"></PageHeader>
                <div className='mt-60' style={{textAlign:"center"}}>
                    <Button className = "btn-primary" onClick = {startScan} disabled={isScanning}>
                        {isScanning && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                        {"Scan Employee QR Card"}
                        {isScanning && "Loading..."}
                    </Button>
                    {errorMessage ? <div className='mt-60 alert alert-danger d-flex align-items-center'>{errorMessage}</div> : null}
                </div>
                
            </Layout>}

            {
                userDetail.isAllowRemark && showRemark ? <RemarkPopup onClick={onClickRemark}></RemarkPopup> : null
            }

            {!isScanning && isShowLoading ? <Modal>
                <div className="mb-3">
                    <div className = {'d-block status-spinner'}>
                        <Status spinner = {true} success = {false} successMessage = {""}/>
                    </div>
                </div>
            </Modal> : null}
            
            {isScanningCompleted && <LoadingModal loading={isShowLoading} text={textLoading}/>}

        </>
    )
}

export default CheckInAdmin;