import { useEffect, useRef, useState } from "react";
import {useHistory } from 'react-router-dom';

//componenets
import Layout from "../Components/Layout";
import PageHeader from "../Components/Common/PageHeader";
import Accordion from "../Components/Common/Accordion" ;
import Form from '../Components/Common/Form';
import ErrorMessage from "../Components/Common/ErrorMessage";
import Actions from '../Components/Common/Actions' ;
import Status from "../Components/Common/Status" ;
import AutoComplete from "../Components/Common/Autocomplete";
import AddBranch from "../Components/AddBranch";
import DatePickerReact from "react-datepicker";
import QRCodeGenerator from '../Components/Common/QRCodeGenerator';
import FormLabel from "../Components/Common/FormLabel";
import ErrorAlert from "../Components/Common/ErrorAlert";


//utils
import {isAutoCompleteValid} from '../Utils/isValidAutoComplete';
import {validation} from '../Utils/validation';
import { splitPascalCase } from "../Utils/splitPascalCase";

//services
import { getBranch } from '../services/branch';
import {  getTeams, createTeam as createTeamService } from '../services/team';
import { getShifts } from '../services/shift';
import { getDesignationList } from '../services/designation';
import {getLeavesList} from '../services/leave';
import { createEmployee as createEmployeeService, updateEmployee as updateEmployeeService, getEmployeesListService, getShiftDetail } from '../services/employee';
import { getCheckinTypes } from '../services/checkinTypes';

// constant
import {employeeStatusList, gpsTrackingList} from '../constant/employee' ;
import { shiftTypesList } from '../constant/shiftTypes' ;
import moment from "moment";
import Toast from "../Components/Common/Toast";
import Button from "../Components/Common/Button";
import Modal from "../Components/Common/Modal";

const AddEmployee = (props) => {
    const router = useHistory();

    // state for set services
    const [ branchList, setBranchList ] = useState([]);
    const [ teamList, setTeamList ] = useState([]);
    const [ shiftList, setShiftList ] = useState([]) ;
    const [ designationList, setDesignationList ] = useState([]);
    const [leaveTypeList, setLeaveTypeList] = useState([]);
    const [ isShowBranchModal, setIsShowBranchModal ] = useState(false);
    const [checkinTypes, setCheckinTypes] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState({});
    const [ createBranchName, setCreateBranchName] = useState("");
    const [ isShowChangeShift, showChangeShift] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState("");

    const isEditEmployee = props.match.params.id ? true : false;

    // state for set status
    const [status, setStatus] = useState({ 
        showStatus : true, 
        spinner : true, 
        success : false, 
        successMessage : '' 
    });

    //state for show and hide image cropper 
    const [isShowImageCropper, setIsShowImageCropper] = useState(false);

    // state for show and hide accordians body
    const[isShowAccordions, setIsShowAccordions] = useState([true, true, true, true]);

    // state for store new employee details
    const [ employee, setEmployee ] = useState({});

    // state for errormessage
    const [errorMessage, setErrorMessage] = useState({});

    
    const commonMessageElement = useRef(null);

    const services = ()=>{
        // get from server
        const pagination = { total:100, itemsPerPage:100, page:1};
        const branchPromise = getBranch(pagination).then( (results)=> {
            let newBranchList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setBranchList([...newBranchList]);
            return newBranchList;
        }).catch(errorHandleService);

        const teamPromise = getTeams(pagination).then( (results)=> {
            let newTeamList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setTeamList([...newTeamList]) ;
            return newTeamList;
        }).catch(errorHandleService);

        getShifts(pagination).then( (results)=> {
            let newShiftList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            console.log(newShiftList)
            setShiftList([...newShiftList]);
        }).catch(errorHandleService);

        getDesignationList(pagination).then( (results)=> {
            let newDesignationList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setDesignationList([...newDesignationList]);
        }).catch(errorHandleService);

        // getLeavesList(pagination).then((results)=>{
        //     let newLeaveList = results.data && results.data.result && results.data.result.length && results.data.result || [];
        //     setLeaveTypeList([...newLeaveList]);
        // });

        getCheckinTypes().then((results)=> {
           const checkinType = results.data.map((type)=> {
                return { ...type, value : type.id };
            })
            setCheckinTypes([...checkinType])
        });

        return [teamPromise, branchPromise]
    }

    const errorHandleService = (error) => {
        if(error.response.status == 401) {
            router.push('/Login') ;
        } else {
            setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        }
    }

    const onCreateTeam = (val) => {
        if ( val ){
            const data = { name : val, description : "Created via add employee"  }
            setIsLoading ( true );
            createTeamService (data).then ( (results)=> {
                const newTeam = { id : results.data.insertId, name : data.name, description : data.description, lead_id: 1  };
                setTeamList( [newTeam, ...teamList] );
                setShowToast({isShow: true, message: "Created Team Successfully!"});
                setTimeout(()=>{
                    setShowToast({isShow: false, message: ""})
                }, 2000);
                setIsLoading ( false );
            }).catch((error)=>{
                console.log(error)
                setIsLoading ( false );
            })
        }    
    }

    const onCreateBranch = (val) => {
        setCreateBranchName(val);
        setIsShowBranchModal(true);
        setIsLoading ( true );
    }

    const closeToastHandler = () => {
        setShowToast({isShow: false, message: ""})
    }


    useEffect(async ()=> {
            const promises = services();
            if(props.match.params.id) {
                const teamBranches = await Promise.all(promises);

                const teams = teamBranches[0];

                const branches =  teamBranches[1];
                setStatus({ ...status, showStatus : true ,spinner : true });
                Promise.all([getEmployeesListService(props.match.params.id), getShiftDetail(props.match.params.id)]).then(([employeeDetail, shiftDetailResult])=>{
                    const {id, employee_id="", first_name, gps, last_name, mail_id, mobile, qr_code, dob,  checkin_type_id, workspace_id, workspace_name, team_id, team_name, designation_id, 
                    designation_name, leave_type_id, leave_type_name, salary, teamRoles=[], branchRoles=[], address, timezone, created_at, updated_at, status} = employeeDetail.data;

                    const { shift_id, name } = shiftDetailResult.data.currentShift;

                    const labelTeamRoles = teamRoles.map(item =>  {
                        const labelTeam = teams.find(tm => tm.id === item.value)
                        return {...item, label: labelTeam ? labelTeam.name: '', id: item.value};
                    });

                    const labelBranchRoles = branchRoles.map(item =>  {
                        const labelTeam = branches.find(bh => bh.id === item.value)
                        return {...item, label: labelTeam ? labelTeam.name: '', id: item.value};
                    })
                    
                    const employeeObj = {...employee, firstName:first_name, lastName:last_name, email:mail_id, checkinType:checkin_type_id, gps, 
                        branch:{ id: workspace_id, value: workspace_id, label: workspace_name}, team: {id: team_id, value:team_id, label:team_name }, 
                        designation: { id: designation_id, name: designation_name}, salary, employeeStatus: status, QRCode: qr_code, 
                        leaveType:{ id: leave_type_id, name: leave_type_name}, shifts: {value:shift_id, label:name}, 
                        address, dob, mobile, shift_id: shift_id, shiftDetail: shiftDetailResult.data,
                        teamRoles: labelTeamRoles, branchRoles: labelBranchRoles};
                    
                        if(shiftDetailResult.data.nextShift) {
                            employeeObj.new_shift = { value: shiftDetailResult.data.nextShift.shift_id, label:  shiftDetailResult.data.nextShift.name};
                            employeeObj.new_shift_start_from = moment(shiftDetailResult.data.nextShift.start_from).toDate();
                        }
                    
                    setStatus({ ...status, showStatus : false ,spinner : true });
                    setEmployee(employeeObj);
                }).catch(()=>{
                    setStatus({ ...status, showStatus : false ,spinner : true });
                    setServerErrorMessage(`Oops! Something went wrong. 
                    Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
                });;
            }
            else {
                setEmployee({
                    imageURL : 'profilePic.png',
                    gps : 1, 
                    checkinType :  1, 
                    employeeStatus : 1
                });
            }
    },[]) ;

    const basicInformation = {
        col1 : [
            { row : [{ type : 'text', key : 'firstName' }, { type : 'text', key : 'lastName'}] },
            { row : [ {type : 'text', key : 'email', validationType : 'email' },  {type : 'text', key : 'mobile', label : 'Mobile number', isMandatory : false, emptyCheck : false}]}
        ]
    };

    const otherInformation = {
        col1 : [
            { row : 
                [
                    {type : 'autocomplete', key : 'team', options : teamList, emptyCheck : false, validationType : 'autocomplete', handleCreate: onCreateTeam, isLoading, isAllowCreate:true},
                    {type : 'autocomplete', key : 'branch', options : branchList, emptyCheck : false, optionText: 'Type some name to create branch', validationType : 'autocomplete',  handleCreate: onCreateBranch, isLoading, isAllowCreate:true}
                ]
            
            },
            { row : [
                {type : 'text', key : 'salary', isMandatory : false, emptyCheck : false},
                { type : 'select', key : 'checkinType', options : checkinTypes}
            ] },
            { row : [
                {type : 'select', key : 'employeeStatus', options : employeeStatusList},
                { type : 'radio', key : 'gps', label : 'GPS tracking' ,  radios : gpsTrackingList }
            ]},
            { row : [
                { type : 'date', key : 'dob', label : 'Date of birth', isMandatory : false, emptyCheck : false},
                {type : 'textarea', key : 'address', isMandatory : false, emptyCheck : false}
            ]},
            { row : 
                [
                    {type : 'autocomplete', label:"Teams Allow Access", key : 'teamRoles', isMandatory : false, emptyCheck : false, options : teamList, emptyCheck : false, validationType : 'autocomplete', isLoading, isMultiple:true},
                    {type : 'autocomplete', label:"Branchs Allow Access", optionText: 'Create Branch above and add it here', key : 'branchRoles', isMandatory : false, emptyCheck : false, options : branchList, emptyCheck : false, validationType : 'autocomplete', isLoading, isMultiple:true}
                ]
            
            },
        ]
    };

    
    const onChangeHandler = (event, key)=>{
        const newEmployee = {...employee} ;
        const newErrorMessage = {...errorMessage} ;
        newEmployee[key] = event.target.value ;
        newErrorMessage[key] = '' ;
        setEmployee({...employee, ...newEmployee}) ;
        setErrorMessage({...errorMessage, ...newErrorMessage}) ;
    };
    
    const addShift = ()=> {
        const newEmployee = {...employee};
        const date = new Date();
        newEmployee.selectShift.push({key : date.getTime()});
        setEmployee({...employee, ...newEmployee});
    };

    const deleteShift = (key)=> {
        const newEmployee = {...employee}
        const index = newEmployee.selectShift.findIndex((shift)=> shift.key == key)
        newEmployee.selectShift.splice(index, 1) ;
        setEmployee({...employee, ...newEmployee}) ;
    };

    const shiftOnSelect = (value, action, key)=> {
        let newEmployee = {...employee};
        newEmployee.shift_id= value? value.id : undefined;
        newEmployee.shifts= value? value : {};
        let shiftErrorMessage = '' ;
        setEmployee({...newEmployee});
        setErrorMessage({...errorMessage, shiftErrorMessage});
    };
    
     // -*- add list item functions (add shift) -*-

    const autoCompleteOnSelect = (value, action, key)=>{
        let newEmployee = {...employee};
        let newErrorMessage = {...errorMessage};
        newErrorMessage[key] = '';
        newEmployee[key] = (key == 'teamRoles' || key == 'branchRoles') ? [...value] : {...value};console.log(value)
        setEmployee({...employee, ...newEmployee});
        setErrorMessage({...newErrorMessage});
    };

    const multiOnSelect = (value, action, key)=>{
        let newEmployee = {...employee};
        let newErrorMessage = {...errorMessage};
        newEmployee[key] = [...value];
        newErrorMessage[key] = '';
        setEmployee({...newEmployee});
        setErrorMessage({...newErrorMessage});
    };

    const parentDiv = useRef();

    const validateSelectShift = ()=>{
        let inValidMessage = {};
        let isValid = true;
        employee.selectShift.map((shift)=>{
            if(!isAutoCompleteValid(shift, shiftList)){
                inValidMessage[shift.key] = 'Please select this field';
                isValid = false;
            };
        });
        return {message : inValidMessage, isValid : isValid}
    };

    const validateBranchField = ()=>{
        let inValidMessage = '';
        let isValid = true;
        if(!employee.branch || !isAutoCompleteValid(employee.branch, branchList)){
            inValidMessage = 'Please select this field';
            isValid = false;
        }
        return {message : inValidMessage, isValid : isValid};
    };
    
    const mergeValidationResults = ()=>{
        const inputFields = basicInformation.col1.concat( otherInformation.col1);
        let isValid = true;
        let inValidMessages = {};
        
        //validation results
        const branchValidation = validateBranchField();
        //const shiftValidation = validateSelectShift()
        const otherFieldValidation = validation(inputFields, employee);
        // -*- validation results -*-

        inValidMessages = {...otherFieldValidation.errorMessage};
        //inValidMessages.selectShift = {...shiftValidation.message};
        if(branchValidation.message) inValidMessages.branch = branchValidation.message;

        isValid = branchValidation.isValid && otherFieldValidation.isValid;
        
        return {isValid : isValid, messages : inValidMessages};
    };

    const createEmployeeValidation =()=>{

        const validationResult = mergeValidationResults();
        let isCreateEmployee = validationResult.isValid;
        if(!employee.shift_id) { 
            validationResult.messages['shiftErrorMessage'] = "Please select shift";
            isCreateEmployee = false;
        };
        let inValidMessages = validationResult.messages;
        if(!isCreateEmployee){
            const errorMessageLabels = Object.keys(inValidMessages);
            inValidMessages.common = 'Please check this fields ' + errorMessageLabels.map((label)=> splitPascalCase(label)).join(', ');
            setIsShowAccordions([true, true, true]);
            commonMessageElement.current && commonMessageElement.current.focus();
   
        }
        setErrorMessage({...inValidMessages});
        return isCreateEmployee;

    };
    const employeeData = ()=>{
        const {firstName, new_shift, new_shift_start_from, lastName, email, checkinType, gps, branch, team, designation, salary, employeeStatus, QRCode, leaveType, shift_id, address, dob, mobile, teamRoles, branchRoles} = employee ;

        const data = {first_name : firstName, last_name : lastName, mail_id : email, mobile, 
            checkin_type_id : checkinType, gps : gps, qr_code: QRCode, workspace_id : branch.id, 
            team_id : team.id, designation_id : designation && designation.id ? designation.id : '', shift_id, 
            leave_type_id : leaveType && leaveType.id ? leaveType.id : '', salary : salary, status : employeeStatus, address, dob, 
            team_roles: getObjectToArrayIds(teamRoles),
            branch_roles: getObjectToArrayIds(branchRoles)
        } ;

        if(isShowChangeShift) {
            data.new_shift = {...new_shift, id: new_shift.value};
            data.new_shift_start_from = new_shift_start_from;
        }

        return data;
    };

    const getObjectToArrayIds = (originalObject)=>{
        if(originalObject && typeof originalObject === 'object') {
            return Object.values(originalObject).map((item)=>{
                return item.id
            });
        }
        return originalObject
    }

    const createEmployee =()=>{
        const isCreateEmployee = createEmployeeValidation();    
        if(isCreateEmployee && !isEditEmployee){
            const data = employeeData();
            const dateObject = new Date().getTimezoneOffset();

            let utc = -Math.round(dateObject/60)+':'+-(dateObject%60);
            utc = utc < 0 ?utc : '+'+utc;
            data.timezone = moment().format('Z');

            setStatus({ ...status, showStatus : true ,spinner : true });
            createEmployeeService(data).then((results) => {
                if(results.data.error == 'input field missing'){
                }
                else{
                    setStatus({ ...status, showStatus : true, spinner : false, success : true, successMessage : 'Employee created successfully' });
                }
                console.log(results)
            }).catch((error)=>{
                setStatus({ ...status, showStatus : true, spinner : false, success : false, successMessage : "Error" });
                console.log("error", error)
            });
        }
        else if(isCreateEmployee && isEditEmployee) {
            setStatus({ ...status, showStatus : true ,spinner : true });
            const data = employeeData();
            updateEmployeeService(data, props.match.params.id).then((results) => {
                setStatus({ ...status, showStatus : true, spinner : false, success : true, successMessage : 'Employee updated successfully' });
            }).catch((error)=>{
                setStatus({ ...status, showStatus : true, spinner : false, success : false, successMessage : "Error" });
            })
        }
    };

    const routeEmployeePage = ()=> {
        router.push('/Employees') ;
        setStatus({ showStatus : false, spinner : false, success : false, successMessage : '' }) ;
    }

    const saveImage = (imageURL)=> {
        let newEmployee = employee ;
        newEmployee.imageURL = imageURL ;
        setEmployee({...employee, newEmployee});
    }

    const showAccordionBody =(index)=>{
        const showAccordions = isShowAccordions; 
        showAccordions[index] = !showAccordions[index];
        console.log(showAccordions)
        setIsShowAccordions([...showAccordions]);
    };

    const renderBasicInformation = ()=> {
        return  (
            <div className = 'row'>
                <div className = 'col-12 col-lg'>
                    <div className = 'row'>
                        <div className = 'col-md-12'>
                            <Form rows = {basicInformation.col1} value = {employee} errorMessage = {errorMessage} onChange = {onChangeHandler} isLoading={isLoading}  />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderOtherInformation = ()=> {
        return(
            <div className = 'row'>
                <div className = 'col-lg col-12 '>
                    <div className = 'row'>
                        <div  className = 'col-md-10'>
                            <Form rows = {otherInformation.col1} value = {employee} errorMessage = {errorMessage} onChange = {onChangeHandler} autoCompleteOnSelect = {autoCompleteOnSelect} multiOnSelect = {multiOnSelect}/>
                        </div>
                        { employee.QRCode && <div className = 'col'>
                            <FormLabel label = 'QR Card' mandatory = {false}/>
                            <QRCodeGenerator download = {true} size = {150} value = {employee.QRCode}/>  
                        </div> }
                    </div>
                </div>
            </div>
        )
    };

    const renderAddShiftInformation = ()=>{
        return(
            <div className = ''>
                <div className = 'row'>
                    <div className = 'col-12'>
                        <div className = 'col-3'>
                            <label className="label mb-1">Select Shift</label>
                            <AutoComplete name = {"shifts"} value = {employee.shifts} onSelect = {(value, action)=> shiftOnSelect(value, action)} options = {shiftList}/>
                            <ErrorMessage label = {errorMessage.shiftErrorMessage && errorMessage.shiftErrorMessage}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const onChangeShift = () => {
        showChangeShift(true)
    }

    const onHideChangeShift = () => {
        setEmployee({...employee, new_shift: false, new_shift_start_from:""})
        showChangeShift(false)
    }

    const shiftOnNewSelect = (value, action, key)=> {
        let newEmployee = {...employee};
        newEmployee.new_shift = value;
        setEmployee({...newEmployee});
    };

    const renderShiftInformation =()=>{
        const currentShift = employee.shiftDetail && employee.shiftDetail.currentShift ? true : null;
        return currentShift ? (
            <div className = 'd-flex'>
                <div className = 'col-lg-6'>
                    <div className="row align-items-center">
                        <div className="col">
                            <div>
                                <label className="label">Current Shift:</label>
                                <span>{employee.shiftDetail.currentShift.name} from {employee.shiftDetail.currentShift.start_from}</span>
                            </div>
                        </div>
                        {employee.shiftDetail.nextShift && <div>
                            Next Shift {employee.shiftDetail.nextShift.name} starts from {employee.shiftDetail.nextShift.start_from} 
                        </div>}
                    </div>
                    
                </div>
            </div>
        ) : renderAddShiftInformation()
    };

    const actions = [{label : 'Back', className : 'btn-secondary', onClick : routeEmployeePage}];
    const accordion = [
        {title : 'Basic Infomation', render : renderBasicInformation()},
        {title : 'Other Infomation', render : renderOtherInformation()},
        {title : 'Shift Infomation', render : renderShiftInformation()},
    ];

    const hideErrorMessage = () => {
        setServerErrorMessage('')
    }

    const commonError = errorMessage.common? '' : 'visually-hidden-focusable';
    return(
        <Layout>
            <div className = { status.showStatus ? 'd-none' : 'd-block f-s13'} ref = {parentDiv}>
                <PageHeader title = {"View Employee"}/>
                {!!serverErrorMessage && <ErrorAlert close={hideErrorMessage}>
                    {serverErrorMessage}
                </ErrorAlert>}
                    <div className = {'p-2 text-danger border border-2 border-danger ' + commonError} ref = {commonMessageElement} tabIndex = '-1'>
                        {errorMessage.common}
                    </div>

                {accordion.map((accordionBody, index)=>{
                    return(
                        <Accordion key = {index} onClick = {()=> showAccordionBody(index)} className = 'border-0 border-bottom rounded-0' title = {accordionBody.title} showBody = {isShowAccordions[index]}>
                            {accordionBody.render}
                        </Accordion>
                    )
                })}

<div className = 'pt-2 pb-2 px-3 d-flex justify-content-end'>
                    <div>
                        <Actions actions = {actions}/>
                    </div>
                   
                </div>

            </div>
            {status.showStatus && <Modal>
                <div className="mb-3">
                    <div className = {status.showStatus ? 'd-block status-spinner' : 'd-none'}>
                        <Status spinner = {status.spinner} success = {status.success} successMessage = {status.successMessage} onClick = {routeEmployeePage}/>
                    </div>
                </div>
            </Modal>}
            {showToast.isShow &&<Toast message={showToast.message} closeToast={closeToastHandler}/>}
        </Layout>
    )
}

export default AddEmployee;
