import { useState }  from 'react' ;
//components
import BackgroundEntryPage from '../Components/BackgroundEntryPage.js';
import FormHeader from '../Components/Common/FormHeader';
import FormControl from '../Components/Common/FormControl';
import Input from '../Components/Common/Input';
import ErrorMessage from '../Components/Common/ErrorMessage';
import Button from '../Components/Common/Button';
//utils
import {isEmailValid} from '../Utils/isEmailValid'
import {isPasswordValid} from '../Utils/IsPasswordValid'
import { loginError } from '../constant/errorMessage'
//sevices
import { login } from '../services/auth'
import {useHistory} from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

const LoginPage=(props)=>{
    const router = useHistory();
    const initialState={
        formFields:
        {
            organizationId : "",
            email : "",
            password : ""
        },
        errorMessage:
        {
            organizationId : "",
            email : "",
            password : "",
            common : ''
        }
    }
    const [state,setState] = useState(initialState);
    const [isLoading, setLoading] = useState(false);
    const [passwordType, setPasswordType] = useState('password');



    const onChangeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const formFields = {...state.formFields, [name]:value};
        const errorMessage = {...state.errorMessage, [name]:""};
        setState({...state, formFields, errorMessage});
    };

    const onClickHandler = async (e)=> {
        e.preventDefault();
        // error messages
        const { email, password } = loginError

        const formFields = Object.keys(state.formFields);
        const errorMessage = {};
        
        let nextPage = true
        
        formFields.map((key)=>{

            if(!state.formFields[key]){
                const message= key.replace(/([a-z0-9])([A-Z])/g, '$1 $2')+" field should be empty";
                errorMessage[key]=message;

                nextPage = false
            }
            else{
                if(!isEmailValid(state.formFields.email)){
                    errorMessage.email = email ;
                    nextPage = false
                }
                 if( ! isPasswordValid (state.formFields.password) ) {
                    errorMessage.password = password ;
                    nextPage = false
                }
            }

        })
        if ( nextPage ) {
            // send data to server
            const data = {

                mail_id : state.formFields.email, 
                user_name : state.formFields.organizationId,
                password : state.formFields.password

            }
            const platformName = Capacitor.getPlatform();
            if(platformName === 'android' || platformName === 'ios') {
                const deviceId = await Device.getId();
                data.device_id = deviceId.identifier;
            }
            setLoading(true);
            login ( data ).then ( (results) => {
                // all details correct get token to send server and redirect to dashboard page
                localStorage.setItem ( 'token', results.data.token )
                router.push ('/Dashboard')

            }).catch ( (error) => {
                setLoading(false);
                if ( error && error.response ) {
                    if ( error && error.response && error.response.data && error.response.data.isOtpRequired) {
                        router.push ('./SignupOtpVerify')
                    }
                    else {
                        if(error && error.response && error.response.data && error.response.data.error) {
                            errorMessage.common = error.response.data.error;
                        }
                        else {
                            errorMessage.common = "There is an error, please try with valid input or try later";
                        }
                    }
                    setState ( { ...state, errorMessage } )
                }
            } )
        }
        setState ( { ...state, errorMessage } )

    }
    const onClickEyeIcon = (e) => {
        setPasswordType(passwordType === 'password' ? 'text' : 'password')
    }

    const onClickForgotPassword = (e) => {
        e.preventDefault();
        router.push('/ForgotPassword')
    }

    const { organizationId, email, password } =state.formFields
    return(
        <BackgroundEntryPage>
            {/* login header includes logo,heading,subheading */}
            <FormHeader primaryHeading="Log In" subHeading="To simplify attendance"/>
            <ErrorMessage label = {state.errorMessage.common}/> 
            <form autoComplete="off" onSubmit = {onClickHandler} >

                <FormControl>
                    <Input  label = "Company Id" name = "organizationId" value = {organizationId}  onChange = {onChangeHandler}/> 
                    <ErrorMessage label = {state.errorMessage.organizationId}/>              
                </FormControl>

                <FormControl>
                    <Input label = "Email" maxLength={254} type = "email" name = "email" value = {email} onChange = {onChangeHandler}  />
                    <ErrorMessage label = { state.errorMessage.email }/>   
                </FormControl>
                
                <FormControl>
                    <Input label = "Password" maxLength={20} type = {passwordType}  name = "password" value = {password} onChange = {onChangeHandler}
                    icon={passwordType === 'password'? <i className="bi bi-eye-fill"></i> : <i className="bi bi-eye-slash-fill"></i>} onCLickIconHandler={onClickEyeIcon} isIConRight={true} />
                    <ErrorMessage label = {state.errorMessage.password}/> 
                </FormControl>

                <Button className = "w-100 btn-primary mt-10 mb-3 btn-sm" type="submit" disabled={isLoading}>{isLoading ? "Loading..." : "Sign In"}</Button>
                <a href={'#'} onClick={onClickForgotPassword}>Forgot Password?</a>
            </form>
            <div className="line mt-2 mb-3 ">
                <h6 className="border-bottom f-s15"><span>or</span></h6>
            </div>
            <div className="text-center ">
                {/* <Button onClick = {()=>{}} className = "btn-link">Forget Password</Button> */}
                <Button onClick = {()=>{ 
                   router.push ('./Signup')
                }} className =  "btn-outline-primary btn-sm">Sign Up</Button>
            </div>

        </BackgroundEntryPage>
    )
}
export default LoginPage;