import Button from './Button.js';

const Modal = (props)=>{
    
    const hideModal = () =>{
        if(props.hideModal) props.hideModal()
    }
    const { className = '', title = '' } = props
    return(
        <>
            <div className = {'modal d-block overflow-auto f-s14 '} tabIndex="-1">
            <div className={"modal-dialog "+className}>
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title">{title}</h5>
                            {!!props.hideModal && <Button className = "btn-close btn" onClick = {hideModal}/>}
                        </div>

                        <div className="modal-body pt-0 pb-0">
                            {props.children}
                        </div>
                    </div>
            </div>
            </div>
        </>
    )
}
export default Modal