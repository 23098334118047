import { useContext, useEffect, useState } from 'react';
// components
import Layout from '../Components/Layout';
import PageHeader from '../Components/Common/PageHeader';
import Table from '../Components/Common/Table';
import Modal from '../Components/Common/Modal';
import ModalFooter from '../Components/Common/ModalFooter';
import Alert from '../Components/Common/Alert';
import Form from '../Components/Common/Form';
import Actions from '../Components/Common/Actions';
import Status from '../Components/Common/Status';
import moment from 'moment';
import { userDetailsContext } from "../context";
//utils
import {validation} from '../Utils/validation';
//services
import { getShifts as getShiftsService, createShift as createShiftService, deleteShift as deleteShiftService } from '../services/shift';
import ErrorAlert from '../Components/Common/ErrorAlert';

const Shift = (props) => {
    // state for get shifts from server
    const [ shiftList, setShiftList ] = useState([]);
    // state for ceate new shift 
    const [ shift, setShift ] = useState({});
    //state errorMessage
    const [errorMessage, setErrorMessage] = useState({})
    // state for show and hide modal
    const [ isShowModal, setIsShowModal ] = useState(false)
    // state show and hide the alert
    const [ isShowAlert, setIsShowAlert ] = useState(false)
    // state for status add end edit team
    const [ status, setStatus ] = useState({ showStatus : false, spinner : false , success : false, successMessage : ''})

    const [paginationDetail, setPaginationDetail] = useState({itemsPerPage:10, page:1, total:10});

    const [isLoader, setLoader] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState("");

    const roleName = useContext(userDetailsContext).role;

     // after reder get shift from server using useEffect
    useEffect ( ()=>{
        setLoader(true);
        getShiftsService().then ( ( results ) => {
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            const shiftLists = [ ...result ];
            setLoader(false);
            shiftLists.forEach((lists) => {
                lists.startTime = convert12Hours(lists.start_at);
                lists.endTime = convert12Hours(lists.end_at);
                lists.maximumTime = convert12Hours(lists.last_end_at);
            });
            setShiftList([ ...shiftLists ]);            
            setPaginationDetail({itemsPerPage, page, total});
        }).catch((err)=>{
            setLoader(false);
            setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        });
    },[true]);

    const showModal =()=>{
        setIsShowModal(true);
    };

    const shiftOnChange =(event, key)=>{
        const newShift = {...shift};
        const newErrorMessage = {...errorMessage}; 
        newShift[key] = event.target.value;
        newErrorMessage[key] = '';
        setShift({...newShift});
        setErrorMessage({...newErrorMessage});
    };
    //to validate input fields to create new shift
    const validateInputFields =()=>{
        const validationResult = validation(inputFields, shift);
        validationBetweenStartEndTime(shift);
        const newErrorMessage = validationResult.errorMessage;
        setErrorMessage({...newErrorMessage});
        return validationResult.isValid;
    };

    const validationBetweenStartEndTime = ({maximumTime, startTime, endTime}) => {
        const dayStartAtDate = moment(maximumTime, 'HH:mm');
        const startTimeDate =  moment(startTime, 'HH:mm');
        const endTimeDate =  moment(endTime, 'HH:mm');
        const dayStartAtDateUTC = moment.utc(dayStartAtDate.format('HH:mm YYYY-MM-DD') , 'HH:mm YYYY-MM-DD');
        return
    }

    const createShift = () => {
        const isCreateShift = validateInputFields();
        if (isCreateShift){

            setStatus({...status, showStatus : true, spinner : true});

            const { shiftName, startTime, endTime, maximumTime, description } = shift;
            //const data = { name : shiftName, start_at : convertSQlFormat(startTime), end_at : convertSQlFormat(endTime), last_end_at : convertSQlFormat(maximumTime), description : description };

            const data = { name : shiftName, start_at : startTime, end_at : endTime, last_end_at : maximumTime, description : description };

            createShiftService(data).then((results)=>{
                const newShiftList = [...shiftList];
                newShiftList.push( { id : results.data.insertId, name : shiftName, description : description, startTime : convert12Hours(startTime), endTime : convert12Hours(endTime), maximumTime : convert12Hours(maximumTime)});
                setShiftList([...newShiftList]);
                setStatus({showStatus : true, spinner : false , success : true, successMessage : 'Shift created successfully'});
            }).catch((err)=>{
                setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
            });
        };
    };

    const convertSQlFormat =(newTime)=>{
        const newDate = new Date('2021-07-24 ' + newTime);
        const date =  newDate.getFullYear() + '-' + ( '0' + (newDate.getMonth()+1) ).slice(-2) + '-' + ( '0' + newDate.getDate() ).slice(-2);
        const time = ( '0' + newDate.getHours() ).slice(-2) + ':' + ( '0' + newDate.getMinutes() ).slice(-2) + ':' +( '0' + newDate.getSeconds() ).slice(-2);
        const dateFormat = date + ' ' + time;
        return dateFormat;
    };

    const convert12Hours =(newTime)=>{
        const newDate = new Date('2021-07-24 ' + newTime);
        let hours = newDate.getHours();
        let timeFomat = 'AM';
        if (hours > 12){
            hours = hours - 12;
            timeFomat = 'PM'
        };
        if(hours == 12) {
            timeFomat = 'PM'
        }
        return ('0' + hours).slice(-2) + ' : ' + ('0' + newDate.getMinutes()).slice(-2) + ' ' + timeFomat;
    };

    const timeOnChange=(newTime, key)=>{
        const newShift = {...shift} ;
        newShift [key] = newTime ;
        setShift({ ...newShift }); 
    };

    const timeOnClick=(newTime, key)=>{
        const newShift = { ...shift} ;
        const newErrorMessage = { ...errorMessage }
        newShift[key] = newTime ;
        newErrorMessage[key] = '';
        setShift ({...newShift});
        setErrorMessage({...newErrorMessage});
    };
    const saveTime =(newTime, key)=>{
        const newShift = { ...shift} ;
        const newErrorMessage = { ...errorMessage }
        newShift[key] = newTime ;
        setShift ({...newShift});
    };
    const hideModal =()=>{
        setShift ({});
        setErrorMessage({});
        setIsShowModal(false);
        setStatus({ ...status, showStatus : false, success : false});
    };

    const showAlert =(shiftInfo)=>{
        let newShift = {id : shiftInfo.id};
        setShift({ ...newShift});
        setIsShowAlert(true); 
    };
    const deleteShift =()=>{
        deleteShiftService (shift.id).then((results)=>{
            let newShiftList = [ ...shiftList ];
            const shiftIndex = newShiftList.findIndex ( (shiftDetails) => shiftDetails.id == shift.id );
            newShiftList.splice(shiftIndex, 1);
            setShiftList([ ...newShiftList ]);
            hideAlert();
        }).catch (()=>{
            setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        });
    };
    const hideAlert =()=> {
        setIsShowAlert(false); 
    };

    const onPaginationRoutePage = (page) => {
        const updatePagination = { ...paginationDetail, page};
        setPaginationDetail(updatePagination);
        getShiftsService(updatePagination).then((results) => {
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            const shiftLists = [ ...result ];
            shiftLists.forEach((lists) => {
                lists.startTime = convert12Hours(lists.start_at);
                lists.endTime = convert12Hours(lists.end_at);
                lists.maximumTime = convert12Hours(lists.last_end_at);
            });
            setShiftList([ ...shiftLists ]);
        }).catch(()=>{
            setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        })
    }

    const onChangeRowsPerPage = (itemsPerPage) => {
        const updatePagination = { ...paginationDetail, itemsPerPage, page:1};
        setPaginationDetail(updatePagination);
        getShiftsService(updatePagination).then((results) => {
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            const shiftLists = [ ...result ];
            shiftLists.forEach((lists) => {
                lists.startTime = convert12Hours(lists.start_at);
                lists.endTime = convert12Hours(lists.end_at);
                lists.maximumTime = convert12Hours(lists.last_end_at);
            });
            setShiftList([ ...shiftLists ]);
        }).catch(()=>{
            setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        })
    }


    const actionsBtn = { 
        label : "Actions", key : "action",
        actions : [
            {
            label : <i className="bi bi-trash-fill"></i>,
            key : "delete",
            className : 'btn-danger ml-10',
            onClick : showAlert 
            }
        ]
    }

    // table header
    const tableHeader = [
        { key : 'name'},
        { key : 'startTime'},
        { key : 'endTime'},
        { key : 'maximumTime', label: "Cut-off time"},
        { key : 'description'}
    ];

    if(roleName === 'admin') tableHeader.push(actionsBtn);

    const inputFields = [
            { row : [ { type : 'text', key : 'shiftName'} ] },
            { row : [ { type : 'time', key : 'startTime' }, { type : 'time', key : 'endTime' } ]  },
            { row : [ { type : 'time', key : 'maximumTime', label:"Cut-off time", helpText:`* Cut Off Time : Set the time when the day changes for shift calculations. For example, if set to 12:00 AM (midnight), shifts starting before this time will be considered for the previous day.` } ] },
            { row : [ { type : 'textarea', key : 'description', maxLength: '100' } ] }
    ];

    //modal  
    const modalTitle = 'Create Shift' ;
    //create shift actions
    const createActions = [ 
        { label : "Close", className : "btn-secondary", onClick : hideModal},
        { label : "Save", className : "btn-primary", onClick : createShift  } 
      
    ];
    // delete shift actions
    const deleteActions = [
        { label : 'Delete', onClick : deleteShift , className : 'btn-danger' },
        { label : 'Close', onClick : hideAlert , className : 'btn-secondary' }
   ];

   const hideErrorMessage = () => {
        setServerErrorMessage('')
    }

   // -*- modal -*-
    return (
        <Layout>
            { (roleName === "admin" || roleName === "branchLead") && <PageHeader title = 'Manage Shift' label = 'Create Shift' onClick =  {showModal } /> }
            { (roleName === 'employee' || roleName === "teamLead") && <PageHeader title = 'Manage Shift' /> }
            {!!serverErrorMessage && <ErrorAlert close={hideErrorMessage}>
                {serverErrorMessage}
            </ErrorAlert>}
            <Table isLoader={isLoader} header = {tableHeader} body = { shiftList } paginationDetail={paginationDetail} routePage={onPaginationRoutePage} onChangeRowsPerPage={onChangeRowsPerPage} />
                {isShowModal && 
                    <Modal title = {modalTitle}  hideModal = {hideModal}>
                        <div className = {!status.showStatus ? 'd-block' : 'd-none'}>
                            <Form rows = {inputFields} className = 'col' value = {shift} errorMessage = {errorMessage} onChange = { shiftOnChange } hideModal = {hideModal} timeOnChange = {timeOnChange} timeOnClick = {timeOnClick} saveTime = {saveTime}/>
                            <ModalFooter>
                                <Actions actions = {createActions}/>
                            </ModalFooter>
                        </div>
                        <div className = {status.showStatus ? 'd-block mb-3' : 'd-none'}>
                            <Status spinner = {status.spinner} success = {status.success} successMessage = {status.successMessage} onClick = {hideModal} />
                        </div>
                    </Modal>       
                }

                { isShowAlert && <Alert label = 'Are you sure delete this item' actions = { deleteActions } hide = { hideAlert } />}
            
            
        </Layout>
    )
}
export default Shift