const Footer=()=>{
    return(
    <div className="footer">
        <div className="my-4">
            {/* <ul className="nav justify-content-center border-bottom pb-3 mb-3">
            <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Home</a></li>
            <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Features</a></li>
            <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Pricing</a></li>
            <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">FAQs</a></li>
            <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">About</a></li>
            </ul> */}
            <p className="text-center text-muted">Powered by Pazhanam Technologies Pvt Ltd.</p>
        </div>
    </div>
    )
}
export default Footer;