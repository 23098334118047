import { useState } from 'react'
import { menuItems } from '../../constant/navItems' ;
import {
    Link,
    useHistory
  } from 'react-router-dom';
import { logout } from '../../Utils/login';
import Modal from './Modal';
import ModalFooter from './ModalFooter';
import Actions from './Actions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const SideBar=(props)=>{
    const history = useHistory();
    const [ page, setPage ] = useState (''); 
    const location = useLocation();


    const [ showPopupScanQR, setShowPopupScanQR] = useState(false);
    
    const changePage = (currentPage, e, currentValue) =>{
        if(currentValue === 'checkinAdmin' && location.pathname !== "/CheckinAdmin") {
            e.preventDefault();
            setShowPopupScanQR(true);
        }
        setPage ( currentPage )
    }

    const onLogout = (e) => {
        e.preventDefault();
        logout();
        history.push('/login');
    }

    const hideModal = () => {
        setShowPopupScanQR(false);
    }

    const gotoScanQR = () => {
        hideModal();
        localStorage.setItem ( 'isRestrictPage', 'true' );
        history.push('/CheckinAdmin');
    }

    const actions = [  
        {label : "No", className : "btn-secondary", onClick : hideModal},
        {label : "Yes", className : "btn-primary", onClick : gotoScanQR }
    ] ;

    

    const isRestrictPage = localStorage.getItem ( 'isRestrictPage' );

    
    const menuList = isRestrictPage === 'true' ? ['checkinAdmin'] : props.menuList;
    return(
        <>
            {props.children}
            <ul className="navbar-nav">
            {
                menuList.map((currentValue)=>{
                    const activeClass = history.location.pathname === menuItems[currentValue].router ? 'active' : '';
                    return (
                    <li key={currentValue.label}>
                        <Link className = {'nav-link fw-600 '+ activeClass} to = { menuItems[currentValue].router } onClick = { (e) => changePage ( menuItems[currentValue].label, e, currentValue) }>
                            {menuItems[currentValue].icon && <i className={"f-s20 "+menuItems[currentValue].icon}></i>}
                            <span>{menuItems[currentValue].label}</span>
                        </Link>
                    </li>
                    )
                })
            }
            <li key={"logout"}>
                <a className = {'nav-link fw-600'} href="#" onClick={onLogout}>
                    <i className="f-s20 bi bi-arrow-bar-left"></i>
                    <span>Logout</span>
                </a>
            </li>
        </ul>
        {showPopupScanQR && <Modal>
            <p>If you go to the Scan QR Kiosk, you can't return to any other page.<br/> The only option to access other pages is to log out and log in again. Continue? <br/><br/>
            <span>Note: Scan QR Kiosk helps to scan Employee QR only.</span></p>
            <ModalFooter>
                <Actions actions = {actions}/>
            </ModalFooter>
        </Modal>}
        </>
    )
}
export default SideBar