import { useState } from 'react'
//components
import Modal from './Common/Modal';
import ModalFooter from './Common/ModalFooter';
import GoogleMap from './Common/Map';
import Actions from './Common/Actions';
import Status from './Common/Status';
//utils
import { isValidNumber } from '../Utils/isValidNumber';
//services
import { createBranch as createBranchService } from '../services/branch' 
import QRCodeGenerator from './Common/QRCodeGenerator';

const AddBranch = (props) => {
    // state for add create Branch 
    const [ branch , setBranch ] = useState({name:props.name || ''});
    // state for errorMessage
    const [errorMessage, setErrorMessage] = useState({});
    // state for save branch address details
    const [ branchAddress, setBranchAddress ] = useState({});
    const [ branchQR, setBranchQR ] = useState();
    // state for status add end edit Branch
    const [ status, setStatus ] = useState({ showStatus : false, spinner : false , success : false, successMessage : ''});

    const validateInputFields = ()=>{
        const validationResult = branch && branch.name ? { isValid: true, errorMessage:{}} : { errorMessage: {  branch:"Please check valid branch name" }};
        let newErrorMessage = validationResult.errorMessage ;
        let isValid = validationResult.isValid ;

        if (!branchAddress.latitude || !branchAddress.longitude || !branchAddress.address){
            isValid = false;
            newErrorMessage.address = 'Please select address using map or autocomplete';
        }
        if( !branchAddress.circle || !isValidNumber(branchAddress.circle)){
            isValid = false;
            newErrorMessage.circle = 'Numbers only allowed';
        }
        setErrorMessage({...newErrorMessage});
        return isValid;
    };

    const branchData = ()=>{
        const {name, QRcode, lead} = branch;
        const { latitude , longitude , address , circle } = branchAddress;
        const data = { name : name, latitude : latitude, longitude : longitude, circle : circle, address : address };
        return data;
    };

    const addBranch = () => {
        const isCreateBranch = validateInputFields();
        const data = branchData();
        if(isCreateBranch){
            setStatus ( {...status, showStatus : true, spinner : true } )
            createBranchService(data).then((results) => {
                setBranchQR(results.data.qr_code_id || '');
                setStatus({ showStatus : true, spinner : false , success : true, successMessage : 'Branch created successfully'});
            }).catch( (error)=>{
                setBranchQR('')
                setStatus({ showStatus : true, spinner : false , success : false, successMessage : 'Error while creating branch'});
            })
        }
    };

    
    const hideModalHandler = () =>{
        if(props.closeModal) {
            props.closeModal()
        }
        if(props.addBranch) {
            const newBranch = { ...branch, ...branchAddress};
            props.addBranch(newBranch);
        }
    }

    //change latitude longitude in map
    const changeLatLng = (lat, lng, currentaddress, radius)=>{
            let newAddress = branchAddress;
            let newErrorMessage = {...errorMessage};
            newAddress.latitude = lat; 
            newAddress.longitude = lng;
            newAddress.address = currentaddress;
            newAddress.circle = radius;
            newErrorMessage.address = '';
            setBranchAddress({...newAddress});
            setErrorMessage({ ...errorMessage ,...newErrorMessage});
    }
    //change circle diameter in map
    const changeCircle = (event)=> {
       let address = branchAddress;
       address.circle = event.target.value;
       setBranchAddress({...branchAddress, ...address});
       if(isValidNumber(event.target.value)){
        let newErrorMessage = {...errorMessage};
        newErrorMessage.circle = '';
        setErrorMessage({...errorMessage, ...newErrorMessage});
       }
    };


    const addActionBtns = [  
        {label : "Close", className : "btn-secondary", onClick : hideModalHandler},
        {label : "Save", className : "btn-primary", onClick : addBranch }
    ] ;


    
    return (
        <>
            <Modal className = 'modal-lg' title = {"Add Branch"} hideModal = {hideModalHandler}>
                <div className = { !status.showStatus ? 'd-block': 'd-none'}>
                    <div className = 'row mb-2'>
                        <div className = 'col'>
                            <div className='row map-autocomplete '>
                                {errorMessage.branch && <div className="invalid-feedback d-block f-s12">{errorMessage.branch}</div>}
                                <div className='col-lg-6'>
                                    <label className='label mb-1'>Name<span className="text-danger f-s20 ml-4">*</span></label>
                                    <input className='input-controls pac-target-input' 
                                    type={"text"} value={branch.name || ""} 
                                    onChange={ (e) => setBranch({...branch, name: e.target.value}) } 
                                    autoComplete="off"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className = 'd-flex'>
                        <div className = 'col'>
                            <>
                                <GoogleMap latitude = { branchAddress.latitude} longitude = { branchAddress.longitude} 
                                address = {branchAddress.address} circle = {branchAddress.circle} changeLatLng = {changeLatLng} 
                                changeCircle = {changeCircle} 
                                addressError = {errorMessage.address} 
                                circleError = {errorMessage.circle}/>                
                            </>
                        </div>
                    </div>
                    <ModalFooter>
                        <Actions actions = {addActionBtns}/>
                    </ModalFooter>
                </div>
                <div className = {status.showStatus ? 'd-block mb-3' : 'd-none'}>
                    <Status spinner = {status.spinner} 
                    success = {status.success} 
                    successMessage = {status.successMessage} 
                    onClick = {hideModalHandler}>
                        {branchQR ? <>
                            <p>
                            1) Download the Branch QR provided below.<br/>
2) Fix the QR code to the office entrance wall. Employees should scan it to check-in/check-out (CheckIn type: Branch QR).
                            </p>
                            <div className = 'd-flex justify-content-center mb-3'>
                                <QRCodeGenerator size = {200} value = { branchQR } download = {true} regenerate = {false} title={branch.name || ''}/>
                            </div> 
                        </> : null}
                    </Status>
                </div>  
            </Modal> 
        </>
    )
}
export default AddBranch