import {useRef} from 'react';
import Button from './Button';
import { Capacitor } from '@capacitor/core';
import { downloadFileOnAndroid } from '../../Utils/download';
import QRCode from 'qrcode.react';

const QRCodeGenerator = (props) => {
    //-----props values --------
    // download = true - download button
    // regenerate = true - generate button
    // downloadQRCode - action in at the time of download
    // regenarateQRCode - pass the value generate QR code
    // value - QRcode generate value

    const { fileName = '', size = 100, title='' } = props
    const canvas = useRef();
    const platformName = Capacitor.getPlatform();

    const downloadQRCode = (e) => {
        let link = document.createElement('a') ;
        link.download = title+'_QR_code_'+new Date().getTime() + '.png' ;
        const parentElement = document.getElementById('qr-id');
        const qrcanvas = parentElement.getElementsByTagName('canvas')[0];
        const QRCard = canvas && canvas.current && canvas.current._canvas ? canvas.current._canvas : qrcanvas;
        link.href = QRCard.toDataURL();
        if(platformName === 'web') {
            link.click() ;
        }
        else if(platformName === 'android' || platformName === 'ios'){
            QRCard.toBlob((blob) => {
                downloadFileOnAndroid(blob, title+'_QR_code_'+new Date().getTime() + '.png')
            })
        }
        if(props.downloadQRCode){
            props.downloadQRCode(e);
        }

       
        
    }

    const regenerateQRCode = (e) => {
        if(props.regenerateQRCode){
            props.regenerateQRCode(e);
        } 
    }

    return (
    <>
    <div>
        <div style = {{width : size , height : size}} id="qr-id">
            <QRCode ref = {canvas} style = {{width : size, height : size}} value = { props.value || '' } size = {400} />
        </div>
        <div style = {{width : size}}>
            {props.regenerate && 
                <Button  onClick = {regenerateQRCode} className = 'btn-sm text-center btn-primary w-100 mt-1' title = 'regenerate QRCode'>
                    <i className = 'bi bi-arrow-clockwise'></i>Regenerate
                </Button>
            }  
            {props.download && 
                <Button onClick = {downloadQRCode} className = 'btn-sm text-center btn-primary w-100 mt-1' title = 'download QRCode'>
                    <i className = 'bi bi-download'></i>Download
                </Button>
            }
        </div>
    </div>


    </>
        )
}

export default QRCodeGenerator 