//components
const Toast = (props)=> {

    const { message="", closeToast=false, isError=false } = props;


    const closeToastHandler = () =>{
        if(closeToast) {
            closeToast();
        }
    }

    return(
        <>
            <div className="position-fixed bottom-0 end-0 p-3" style={{"zIndex": "11"}}>
                <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className={`toast-body text-white ${isError ? 'bg-danger' : 'bg-success'}`}>
                        {message}
                        <button onClick={closeToastHandler} type="button" className="btn-close align-right text-white" style={{"float": "right"}} data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Toast ;
