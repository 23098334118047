const TOKEN_KEY = 'token';
const IS_RESTRICT_PAGE = 'isRestrictPage';

export const login = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem (IS_RESTRICT_PAGE );
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }

    return false;
}