import { useState, useEffect, useRef, useContext } from 'react'
//components
import Layout from '../Components/Layout';
import PageHeader from '../Components/Common/PageHeader';
import Table from '../Components/Common/Table';
import Modal from '../Components/Common/Modal';
import ModalFooter from '../Components/Common/ModalFooter';
import GoogleMap from '../Components/Common/Map';
import QRCodeGenerator from '../Components/Common/QRCodeGenerator';
import Tap from '../Components/Common/Tap' ;
import Form from '../Components/Common/Form';
import Actions from '../Components/Common/Actions';
import Status from '../Components/Common/Status';
import Alert from '../Components/Common/Alert';
import AutoComplete from 'react-select';
import { userDetailsContext } from "../context";
//utils
import { generateUUID} from '../Utils/generateUUID';
import { getEmployeesListByKeyword } from '../services/employee';
import {validation} from '../Utils/validation' ;
import { isValidNumber } from '../Utils/isValidNumber';
//services
import { getBranch as getBranchService, createBranch as createBranchService, updateBranch as updateBranchService, deleteBranch as deleteBranchService } from '../services/branch' 
import ErrorAlert from '../Components/Common/ErrorAlert';

const Branch = (props) => {
    // state for get team from server and send to table
    const [ branchList, setBranchList ] = useState([]);
    // state show and hide modal
    const [ isShowModal, setIsShowModal ] = useState(false);
    // state for change modal add or edit
    const [ addEdit, setAddEdit ] = useState();
    // state for add create shift 
    const [ branch , setBranch ] = useState({});
    // state for errorMessage
    const [errorMessage, setErrorMessage] = useState({});
    // state for change tap in modal (map & QRCode)
    const [activeTap, setActiveTap] = useState('map');
    // state for save branch address details
    const [ branchAddress, setBranchAddress ] = useState({});
    // state for get employee list (for lead autocomplete)
    const [ employeeList, setEmployeeList ] = useState([]);
    // state show and hide the alert
    const [ isShowAlert, setIsShowAlert ] = useState(false);
    // state for status add end edit team
    const [ status, setStatus ] = useState({ showStatus : false, spinner : false , success : false, successMessage : ''});

    const [ branchQR, setBranchQR ] = useState();

    const [paginationDetail, setPaginationDetail] = useState({itemsPerPage:10, page:1, total:10});

    const roleName = useContext(userDetailsContext).role;

    const [serverErrorMessage, setServerErrorMessage] = useState("");

 
    const [isLoading, setIsLoading] = useState(false);
    const [isLoader, setLoader] = useState(false);
    const [autoValue, setAutoValue] = useState("");

    useEffect(() => {
        setLoader(true);
        getBranchService().then((results) => {
            // push name in employee list for autocomplete             
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            let branchList = [] ;
            result.map((branch)=>{
                branchList.push({...branch})
            })
            setLoader(false);
            setBranchList (branchList)
            setPaginationDetail({itemsPerPage, page, total});
        }).catch( () => {
            setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
            setLoader(false)
        }) 
        // getEmployeesList().then((results)=> {
        //     let employeesList = []
        //     results.data.map((employee)=> {
        //         employeesList.push({...employee, name : employee.first_name + ' ' + employee.last_name})
        //     })
        //     setEmployeeList(employeesList)
        // }).catch()
    },[true]);

    const showModal = (addOrEdit, branchInfo) => {
       if(addOrEdit == 'addBranch'){
            setAddEdit('addBranch');
            setBranch({QRcode : generateUUID()});
       }
       else{
        const newBranch = {};
        const newBranchAddress = {};
        newBranch.id = branchInfo.id;
        newBranch.name = branchInfo.name;
        newBranch.QRcode = branchInfo.qr_code_id;
        newBranchAddress.longitude = branchInfo.longitude;
        newBranchAddress.latitude = branchInfo.latitude; 
        newBranchAddress.address = branchInfo.address;
        newBranchAddress.circle = branchInfo.circle;
        setBranch({...newBranch});
        setBranchAddress({...newBranchAddress});
        setAddEdit(addOrEdit);
       }
       setErrorMessage({});
       setIsShowModal(true);
    }

    const hideModal = () => {
        setIsShowModal(false);
        setBranch({});
        setBranchAddress({});
        setErrorMessage({});
        setStatus( { ...status, showStatus : false , success : false } );
    }

    const branchOnChange = ( event, key) => {
        const newBranch = {...branch};
        const newErrorMessage = {...errorMessage};
        newBranch[key] = event.target.value;
        newErrorMessage[key] = '';
        setBranch ({...newBranch});
        setErrorMessage({...newErrorMessage});
    }

    const validateInputFields = ()=>{
        const validationResult = branch && branch.name ? { isValid: true, errorMessage:{}} : { errorMessage: {  branch:"Please check valid branch name" }};
        let newErrorMessage = validationResult.errorMessage ;
        let isValid = validationResult.isValid ;

        if (!branchAddress.latitude || !branchAddress.longitude || !branchAddress.address){
            isValid = false;
            newErrorMessage.address = 'Please select address using map or autocomplete';
        }
        if( !branchAddress.circle || !isValidNumber(branchAddress.circle)){
            isValid = false;
            newErrorMessage.circle = 'Numbers only allowed';
        }
        setErrorMessage({...newErrorMessage});
        return isValid;
    };


    const branchData = ()=>{
        const {name, QRcode, lead} = branch;
        const { latitude , longitude , address , circle } = branchAddress;
        const data = { name : name, latitude : latitude, longitude : longitude, circle : circle, address : address };
        return data;
    };


    const addBranch = () => {
        const isCreateBranch = validateInputFields();
        const data = branchData();
        if(isCreateBranch){
            setStatus ( {...status, showStatus : true, spinner : true } )
            createBranchService(data).then((results) => {
                const newBranch = { id : results.data.insertId, ...branch, ...branchAddress};
                setBranchList([newBranch, ...branchList]);
                setBranchQR(results.data.qr_code_id || '')
                setStatus({ showStatus : true, spinner : false , success : true, successMessage : 'Branch created successfully'});
            }).catch( (error)=>{
                setBranchQR('')
                setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
                setStatus({ showStatus : false, spinner : false , success : false, successMessage : 'Error while creating branch'});
            })
        }
    };


    const updateBranch = () =>{
        const isUpdateBranch = validateInputFields();
        if ( isUpdateBranch ) {
            setStatus ( {...status, showStatus : true, spinner : true } )

            const {id } = branch;
            const data = {id : branch.id, ...branchData()};
            updateBranchService (data).then ( (results)=>{
                const branchLists = [...branchList];
                const branchIndex = branchLists.findIndex(branches => branches.id == branch.id);
                branchLists[branchIndex] = {...branchLists[branchIndex], ...branch, ...branchAddress};
                setBranchList([...branchLists]);
                setStatus ({ showStatus : true, spinner : false , success : true, successMessage : 'Branch updated successfully'});
            }).catch((error)=>{
                setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
            });
        }
    }

    const autoCompleteOnSelect = (value, action, key)=> {
        const newBranch = {...branch};
        newBranch[key] = value; 
        const newErrorMessage = {...errorMessage};
        newErrorMessage[key] = '';
        setBranch({...branch, ...newBranch});
        setErrorMessage({...newErrorMessage});
    };

    const showAlert = (branchInfo) => {
        setBranch({id : branchInfo.id});
        setIsShowAlert(true); 
    }

    const deleteBranch = () => {
        deleteBranchService (branch.id).then((results)=>{
            const newBranchList = [ ...branchList ];
            const branchIndex = newBranchList.findIndex((brancheDetails) => brancheDetails.id == branch.id);
            newBranchList.splice( branchIndex, 1);
            setBranchList([ ...newBranchList]);
            hideAlert();
        }).catch (()=>{
            setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        })
    }

    const onPaginationRoutePage = (page) => {
        const updatePagination = { ...paginationDetail, page};
        setPaginationDetail(updatePagination);
        getBranchService(updatePagination).then((results) => {
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            let branchList = [] ;
            result.map((branch)=>{
                branchList.push({...branch})
            })
            setBranchList(branchList);
            
        }).catch(()=>{
            setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        })
    }

    const onChangeRowsPerPage = (itemsPerPage) => {
        const updatePagination = { ...paginationDetail, itemsPerPage, page:1};
        setPaginationDetail(updatePagination);
        getBranchService(updatePagination).then((results) => {
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            let branchList = [] ;
            result.map((branch)=>{
                branchList.push({...branch})
            })
            setBranchList(branchList);
        }).catch(()=>{
            setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        })
    }

    const hideAlert = () => {
        setIsShowAlert(false) 
    }


     //change latitude longitude in map
    const changeLatLng = (lat, lng, currentaddress, radius)=>{
        let newAddress = branchAddress;
        let newErrorMessage = {...errorMessage};
        newAddress.latitude = lat; 
        newAddress.longitude = lng;
        newAddress.address = currentaddress;
        newAddress.circle = radius;
        newErrorMessage.address = '';
        setBranchAddress({...newAddress});
        setErrorMessage({ ...errorMessage ,...newErrorMessage});
    }
    //change circle diameter in map
    const changeCircle = (event)=> {
        let address = branchAddress;
        address.circle = event.target.value;
        setBranchAddress({...branchAddress, ...address});
        if(isValidNumber(event.target.value)){
            let newErrorMessage = {...errorMessage};
            newErrorMessage.circle = '';
            setErrorMessage({...errorMessage, ...newErrorMessage});
        }
    };
    
    const changeTap = (tap)=> {
        setActiveTap(tap);
    };

    const actionsBtn = { 
        key : "action", label : "Actions",
        actions:[
            {
                label : <i className="bi bi bi-card-text"></i>,
                key : "view",
                className : 'btn-primary',
                onClick : (branchInfo) => showModal ( 'viewBranch', branchInfo )
            },
            {
                label : <i className="bi bi-pencil-fill"></i>,
                key : "edit",
                className : 'btn-primary ml-10',
                onClick : (branchInfo) => showModal ( 'editBranch', branchInfo )
            },
            {
                label : <i className="bi bi-trash-fill"></i>,
                key : "delete",
                className : 'btn-danger ml-10',
                onClick : (branchInfo) => showAlert ( branchInfo )
            }
        ]
    }

    const header = [
        { key : 'name', label : 'Name'},
        { key : 'address'},
        { key : 'circle'}
    ]

    if(roleName === 'admin') header.push(actionsBtn);

    //  Branch input Fields
    const inputFields = [
        { row : [ { type : 'text', key : 'name', sizeClass : 'col-md-6 col-12' }, { type : 'autocomplete', sizeClass : 'col-md-6 col-12' ,key : 'lead', options : employeeList, emptyCheck : false, validationType : 'autocomplete' }]}        
    ]
    // change modal add or edit
    const modalTitle = addEdit == 'addBranch' ? 'Create new branch' : addEdit === "viewBranch" ? 'View Branch' : 'Edit branch' ;

    const add = [  
        {label : "Close", className : "btn-secondary", onClick : hideModal},
        {label : "Save", className : "btn-primary", onClick : addBranch }
    ] ;

    const edit = [ 
        {label : "Close", className : "btn-secondary", onClick : hideModal },
        {label : "Update", className : "btn-primary", onClick : updateBranch }
    ] ;

    const actions = addEdit == 'addBranch' ? add : edit ;

    // delete shift actions
    const deleteActions = [
        { label : 'Close', onClick : hideAlert , className : 'btn-secondary' },
        { label : 'Delete', onClick : deleteBranch , className : 'btn-danger' },
    ];
    // tapMenu data for modal (map and qrcode)
    const tapMenu = [{key : 'map'}, {key : 'QRCode', label : 'QR Code'}] ;
    //show regenerate QR Code edit branch only
    const regenerateQRCode = addEdit == 'editBranch' ? true : false ;

    const handleCreate = (inputValue) => {
        if(inputValue && inputValue.length > 1) {
            setIsLoading(true);
            getEmployeesListByKeyword(inputValue).then((results)=> {
                let employeesList = []
                setIsLoading(false);
                results.data.map((employee)=> {
                    employeesList.push({...employee, label : employee.first_name + ' ' + employee.last_name, value:employee.id})
                })
                setEmployeeList(employeesList)
            }).catch(()=>{
                setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
            })
        }
    };

    const hideErrorMessage = () => {
        setServerErrorMessage('')
      }
    
    return (
        <Layout>
            { (roleName === 'admin') && <PageHeader title = 'Manage Branch' label = 'Add Branch' onClick = {() => showModal('addBranch')} /> }
            { (roleName === 'employee' || roleName === "teamLead" || roleName === "branchLead") && <PageHeader title = 'Manage Branch' /> }
            {!!serverErrorMessage && <ErrorAlert close={hideErrorMessage}>
                {serverErrorMessage}
               </ErrorAlert>}
            <Table header = {header} isLoader={isLoader} body = { branchList } paginationDetail={paginationDetail} routePage={onPaginationRoutePage} onChangeRowsPerPage={onChangeRowsPerPage} />
            {isShowModal && 
                <Modal className = 'modal-lg' title = {modalTitle} hideModal = {hideModal}>
                    <div className = {!status.showStatus ? 'd-block' : 'd-none'}>
                       { addEdit !== "viewBranch" && <div className = 'row mb-2'>
                            <div className = 'col'>
                                <div className='row map-autocomplete '>
                                    {errorMessage.branch && <div className="invalid-feedback d-block f-s12">{errorMessage.branch}</div>}
                                    <div className='col-lg-6'>
                                        <label className='label mb-1'>Name<span className="text-danger f-s20 ml-4">*</span></label>
                                        <input className='input-controls pac-target-input' type={"text"} value={branch.name || ""} onChange={ (e) => setBranch({...branch, name: e.target.value}) } autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>} 

                        <div className = 'd-flex'>
                            <div className = 'col'>
                                { addEdit !== 'addBranch' && addEdit !== "viewBranch" ? <Tap tapMenu = {tapMenu} activeTap = {activeTap} changeTap = {changeTap}>
                                    {activeTap == 'map' && 
                                    <>
                                        <GoogleMap latitude = { branchAddress.latitude} longitude = { branchAddress.longitude} address = {branchAddress.address} circle = {branchAddress.circle} changeLatLng = {changeLatLng} changeCircle = {changeCircle} addressError = {errorMessage.address} circleError = {errorMessage.circle}/>                
                                    </>
                                    }
                                    {activeTap == 'QRCode' &&
                                        <div className = 'd-flex justify-content-center mt-3'>
                                            <QRCodeGenerator size = {200} value = { branch.QRcode} download = {true} regenerate = {false} title={branch.name}/>
                                        </div> 
                                    }
                                </Tap> : 
                                addEdit === "viewBranch" ? <>
                                    <p>Name: {branch.name}</p>
                                    <p>Address: {branchAddress.address}</p>
                                    <p>Circle: {branchAddress.circle}</p>
                                    <div className = 'd-flex justify-content-center mt-3'>
                                    <QRCodeGenerator size = {200} value = { branch.QRcode} download = {true} regenerate = {false} title={branch.name}/>
                                </div>
                                </>
                                :
                                <>
                                    <GoogleMap latitude = { branchAddress.latitude} longitude = { branchAddress.longitude} address = {branchAddress.address} circle = {branchAddress.circle} changeLatLng = {changeLatLng} changeCircle = {changeCircle} addressError = {errorMessage.address} circleError = {errorMessage.circle}/>                
                                </>
                                }
                            </div>
                        </div>
                        <ModalFooter>
                            {addEdit !== "viewBranch" && <Actions actions = {actions}/>}
                        </ModalFooter>
                    </div>
                    <div className = {status.showStatus ? 'd-block mb-3' : 'd-none'}>
                        <Status spinner = {status.spinner} success = {status.success} successMessage = {status.successMessage} onClick = {hideModal}>
                        {!!branchQR && <>
                            <p>
                            1) Download the Branch QR provided below.<br/>
2) Fix the QR code to the office entrance wall. Employees should scan it to check-in/check-out (CheckIn type: Branch QR).
                            </p>
                            <div className = 'd-flex justify-content-center mb-3'>
                                <QRCodeGenerator size = {200} value = { branchQR } download = {true} regenerate = {false} title={""}/>
                            </div> 
                        </>}
                        </Status>
                    </div>  
                </Modal>      
            }

            { isShowAlert && <Alert label = 'Are you sure delete this item' actions = { deleteActions } hide = { hideAlert } />}
            
        </Layout>
    )
}
export default Branch