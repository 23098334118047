//components
import FormLabel from "./FormLabel";
const Input =(props)=>{
    const { isIConRight=false, label, type="text", name, onChange, onKeyDown, onFocus, value, maxLength="30" , className = "", placeholder, readOnly= false, icon , helpText, accept = '', mandatory = true } = props
        
    const onChangeHandler = (e) =>{
            if(onChange) {
                onChange(e);
            };
        };

        const onKeyDownHandler = (e) =>{
            if(onKeyDown){
                onKeyDown(e);
            };
        };

        const onFocusHandler = e => {
            if(onFocus){
                onFocus(e);
            };
        };

        const onBlurHandler = e => {
            if(props.onBlurHandler) {
                props.onBlurHandler(e);
            };
        };

        const onCLickIconHandler = e => {
            if(props.onCLickIconHandler) {
                props.onCLickIconHandler(e);
            }
        }

        return(
            <>
                { label && type != 'hidden' &&  <FormLabel label = {label} mandatory = {mandatory}/> }
                <div className = "input-group">
                    { icon && !isIConRight &&<span className =  "input-group-text f-s13 bg-white" onClick={onCLickIconHandler}  > { icon } </span> }
                    <input 
                            className={"form-control f-s13 "+className} 
                            maxLength={maxLength} value={value}
                            placeholder={placeholder}
                            type={type}
                            name={name}
                            onChange={onChangeHandler} 
                            onKeyDown={onKeyDownHandler}
                            onFocus={onFocusHandler} 
                            onBlur={onBlurHandler} 
                            readOnly = {readOnly}
                            accept = {accept}
                            autoComplete="off" 
                          
                    />
                    { icon && isIConRight &&<span className =  "input-group-text f-s13 bg-white" onClick={onCLickIconHandler}  > { icon } </span> }
                </div>
                {helpText  && <div className = 'form-text m-0 f-s11' > {helpText} </div>}
                
            </>
        )
}
export default Input