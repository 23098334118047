import React, { useEffect } from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import { App as CapacitorApp } from '@capacitor/app';
import PublicRoute from "./PublicRouter";
import Login from '../Pages/Login';
import ForgetPassword from '../Pages/ForgetPassword';
import ForgetChoosePassword from '../Pages/ForgetChoosePassword';
import Signup from '../Pages/Signup';
import SignupOtpVerify from '../Pages/SignupOtpVerify';
import Logged from '../Components/Logged';




export default () => {

  useEffect(() => {

    const backHandler = (a) => {
      const pageName = localStorage.getItem("pageName");
      if (a.canGoBack && pageName !== "checkInQR") {
        // If there's a previous route, go back
        window.history.back();
      } else if(pageName !== "checkInQR") {
        // If no previous route, exit the app
        CapacitorApp.exitApp();
      }
      else {
        if(window.stopScan) window.stopScan(true);
      }
    };

    // Listen for the back button press event
    CapacitorApp.addListener('backButton', backHandler);

    // Remove the listener when the component unmounts
    return () => {
      CapacitorApp.removeListener('backButton', backHandler);
    };
  }, []);

  return (    
    <>  
      <Switch>
          <PublicRoute component={Signup} path="/signup" restricted={true} />
          <PublicRoute component={Login} path="/login" restricted={true} />
          <PublicRoute component={ForgetPassword} path="/ForgotPassword" restricted={true} />
          <PublicRoute component={ForgetChoosePassword} path="/ResetPassword" restricted={true} />
          <PublicRoute component={SignupOtpVerify} path="/SignupOtpVerify"  restricted={true} />
          <Route component={Logged} path="/" />
      </Switch>
    </>
  );
}