import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { Geolocation } from '@capacitor/geolocation';
import { getGpsLocationAddress } from '../services/attendance';
import { CheckMockLocation } from "capacitor-check-mock-location";
import { Capacitor } from '@capacitor/core';

const startCheckin = async ({ isCheckGps = false, isRemoteOnly = false }, onCallback, onErrorMessage, callBackClose) => {
    window.callBackClose = callBackClose;
    if(!isRemoteOnly) {
        document.querySelector('body')?.classList.add('barcode-scanner-active');
        document.getElementById("closeQR").addEventListener("click", ()=>stopScan(true));
        document.getElementById("divId").style.display = 'flex';
        localStorage.setItem("pageName", "checkInQR");
    
        const listener = await BarcodeScanner.addListener(
            'barcodeScanned',
            async result => {
              stopScan();
              if(result.barcode) {
                let gpsData = {}
                if(isCheckGps) {
                    const { message, latitude, longitude, address } = await onCheckInGps();
                    if(message) {
                        onErrorMessage(message);
                    }
                    else {
                        gpsData = {latitude, longitude, address};
                        onCallback({...result, ...gpsData});
                    }
                }
                else {
                    onCallback({...result, ...gpsData});
                }
              }
              else {
                onErrorMessage("Invalid QR, please check with your admin");
                //document.getElementById("closeQR").removeEventListener("click");
              }
            },
        );
        
        if(await checkPermissions() !== 'denied') {
            await BarcodeScanner.startScan({ lensFacing: 'BACK' });
        }
        else {
            await requestPermissions()
            if(await checkPermissions() !== 'denied') {
                await BarcodeScanner.startScan({ lensFacing: 'BACK' });
            }
            else {
            onErrorMessage("Permission not allowed");
            }
        }
    }
    else {
        let gpsData = {}
        if(isCheckGps) {
            const { message, latitude, longitude, address } = await onCheckInGps(true);
            if(message) {
                onErrorMessage(message);
            }
            else {
                gpsData = {latitude, longitude, address};
                onCallback({...gpsData});
            }
        }
        else {
            onCallback({...gpsData});
        }
    }
    

}

const stopScan = async (isManualClose = false) => {
    document.querySelector('body')?.classList.remove('barcode-scanner-active');
    localStorage.setItem("pageName", "");
    document.getElementById("divId").style.display = 'none';
    //document.getElementById("closeQR").removeEventListener("click");
    if(window.callBackClose) window.callBackClose(isManualClose);
    await BarcodeScanner.removeAllListeners();
    await BarcodeScanner.stopScan();
};

const checkPermissions = async () => {
    const { camera } = await BarcodeScanner.checkPermissions();
    return camera;
};

const requestPermissions = async () => {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera;
};

const onCheckInGps = async  (isOnlyGps = false) => {
    try {
        const platformName = Capacitor.getPlatform();
        if(isOnlyGps && window.callBackClose) window.callBackClose(false);
        const isMocked = platformName === 'ios' ? false : await CheckMockLocation.isMocked();
        if(isMocked && isMocked.value) {
           return { message: "Mocking location is not allowed to checkin, please check with your admin if you are having any query"}
        }
            

        const coordinates = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });
        if(coordinates && coordinates.coords && coordinates.coords.latitude) {                
            const addressResult =await getGpsLocationAddress(coordinates.coords);
            return { latitude: coordinates.coords.latitude, longitude: coordinates.coords.longitude, address: addressResult.data && addressResult.data.results && addressResult.data.results.length ? addressResult.data.results[0].formatted_address : '' }
        }
        return { message: 'Please check that your gps is enabled'};
    }
    catch(error) {
        console.log(JSON.stringify(error));
        if(error.message === "Location permission was denied") {
            return { message: "Location permission was denied. Please go to App Settings and enable location access."};
        }
        else {
            return { message: "You are not able to Check-in due to technical issue, please contact admin"};
        }
    }
}

window.stopScan = stopScan;

export default startCheckin;