
const Modal = (props)=>{
    const { loading = true, text = "Loading..."} = props
    return(
        <>
            <div className = {'modal d-block overflow-auto f-s14 '} tabIndex="-1">
                <div className={"modal-dialog"}>
                    <div className="modal-content">
                        <div className="modal-body pt-0 pb-0">
                            { loading && <div className="d-flex justify-content-center">
                                <div className="spinner-border m-5 mb-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div> }
                            { !loading && <div class="animation-ctn">
                                <div class="icon icon--order-success svg">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">  
                                        <g fill="none" stroke="#22AE73" stroke-width="2"> 
                                        <circle cx="77" cy="77" r="72" className="circleOne"></circle>
                                        <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" className="circleTwo"></circle>
                                        <polyline class="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " className="polyLineOne"/>   
                                        </g> 
                                    </svg>
                                </div>
                            </div>}
                            <div className="d-flex justify-content-center mb-5">{text}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Modal