import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Button from './Common/Button';
import { Capacitor } from '@capacitor/core';
import { downloadFileOnAndroid } from '../Utils/download';


const ExcelSheet = ({excelData, fileName, exportKeys, label="Export to Excel"}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const platformName = Capacitor.getPlatform();

    const newExcel = excelData.map( (data) => {
        const newData = {};
        exportKeys.forEach(element => {
            newData[element.label || element.key] =  data[element.key];
        });
        return newData;
    })

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(newExcel);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        if(platformName === 'android' || platformName === 'ios') {
            downloadFileOnAndroid(data, fileName +new Date().getTime()+ fileExtension)
        }
        else if(platformName === 'web'){
            FileSaver.saveAs(data, fileName +new Date().getTime()+ fileExtension);
        }
    }


    return (
        <>
            <Button className="btn btn-success btn-sm" onClick={exportToExcel}>{label}</Button>
        </>
    )
}

export default ExcelSheet;