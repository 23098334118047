//utils
import { splitPascalCase } from "./splitPascalCase";
import { isValidNumber } from './isValidNumber';
import {isEmailValid} from './isEmailValid' ;
import {isAutoCompleteValid} from './isValidAutoComplete' ;

export const validation = (fields = [], value = {})=> {
    const errorMessage = {} ;
    let isValid = true ;
    if(fields.length){
        fields.map((field)=>{
            if(field.row && field.row.length){
                field.row.map((col)=>{
                    let emptyCheck = true ;
                    if(typeof col.emptyCheck == 'undefined'){
                        emptyCheck = true ;
                    }
                    else{
                        emptyCheck = col.emptyCheck ;
                    }
                    // convert key into label using split pascal case 
                    const label = col.label || splitPascalCase(col.key)
                    //to check value not empty
                    if(!value[col.key] && value[col.key] !==0 && emptyCheck){
                        errorMessage[col.key] = label + ' field should be empty' ;
                        isValid = false ;
                    }
                    //to check value is number
                    else if(col.validationType == 'number' && !isValidNumber(value[col.key])){
                        errorMessage[col.key] = 'Numbers only allowed' ;
                        isValid = false ;
                    }
                    //to check email is correct format
                    else if(col.validationType == 'email' && !isEmailValid(value[col.key])){
                        errorMessage[col.key] = 'Please enter valid email' ;
                        isValid = false ;
                    }
                    //to check to autocomplete value match in suggestions list
                    else if (col.validationType == 'autocomplete' && !isAutoCompleteValid(value[col.key], col.options) && emptyCheck){
                        errorMessage[col.key] = 'Please select '+label ;
                        isValid = false
                    }
                    //isValid = false;

                })
            }
        })
    }
    return { errorMessage : errorMessage, isValid : isValid }
}