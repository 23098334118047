import Layout from '../Components/Layout';
import BigCalendar from '../Components/Common/BigCalendar';
import DatePicker from 'react-datepicker';
import { useContext, useEffect, useState } from 'react';
import Form from '../Components/Common/Form';
import Badge from '../Components/Common/Badge';
import Button from '../Components/Common/Button';
import Modal from '../Components/Common/Modal';
import ModalFooter from '../Components/Common/ModalFooter';
import Actions from '../Components/Common/Actions';
import moment from 'moment';
import Table from '../Components/Common/Table';
import { getEmployeesListByKeyword } from '../services/employee';
import { getAttendanceByDates } from '../services/attendance';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { userDetailsContext } from "../context"
//utils
import {getDates as getBetweenDays} from '../Utils/addDay';
import {convertDate} from '../Utils/convertDate';
import ExportToExcel from '../Components/ExcelSheet';
import PageHeader from '../Components/Common/PageHeader';
import AutoComplete from "../Components/Common/Autocomplete";
import ErrorAlert from '../Components/Common/ErrorAlert';



const Attendance = (props)=>{
    const attendanceList = [
        {
          checkin : new Date('December 09, 2021 08:00:00'),
          checkOut : new Date('December 09, 2021 18:00:00')
        },
        {
          checkin : new Date('December 10, 2021 08:00:00'),
          checkOut : new Date('December 10, 2021 18:00:00')
        },
        {
          checkin : new Date('December 11, 2021 08:00:00'),
          checkOut : new Date('December 11, 2021 18:00:00')
        },
        {
          checkin : new Date('December 11, 2021 08:00:00'),
          checkOut : new Date('December 11, 2021 18:00:00')
        },
        {
          checkin : new Date('December 11, 2021 08:00:00'),
          checkOut : new Date('December 11, 2021 18:00:00')
        },
        {
          checkin : new Date('December 11, 2021 08:00:00'),
          checkOut : new Date('December 11, 2021 18:00:00')
        }
    ];

    const [ attendanceDate, setAttendanceDate ] = useState({ startDate:"", endDate:"", employee: false});
    const [ gridData, setGridData ] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [ employeeList, setEmployeeList ] = useState([]);
    const roleName = useContext(userDetailsContext).role;
    const [locations, setLocations] = useState([]);
    const [notes, setNotes] = useState([]);
    const [serverErrorMessage, setServerErrorMessage] = useState("");

    useEffect(()=>{
      const newAttendanceList = attendanceList.map((attendance)=>{
        const start = new Date(attendance.checkin);
        const end=  new Date(attendance.checkOut);
        start.setHours("00");
        end.setHours("01");
        return {...attendance, start : start, end : end, title : convert12Hours(attendance.checkin) + "-" + convert12Hours(attendance.checkOut), allDay : false}
      });
      //console.log(newAttendanceList)
      setEventList([...newAttendanceList]);
    },[true])

    const convert12Hours =(date)=>{
      const newDate = new Date(date);
      let hours = newDate.getHours();
      let timeFomat = 'AM';
      if (hours > 12){
          hours = hours - 12;
          timeFomat = 'PM'
      };
      return ('0' + hours).slice(-2) + ' : ' + ('0' + newDate.getMinutes()).slice(-2) + ' ' + timeFomat;
  };
    const [eventList, setEventList] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [state, setState] = useState({});

    // const onSelectSlot = (selectDate)=>{
    //     const dates = [...selectDate.slots];
    //     const startDate = new Date(dates[0]);
    //     const endDate = new Date(dates[dates.length-1]);
    //     endDate.setMinutes('01');
    //     setState({startDate : startDate, endDate : endDate});
    // };
    const onSelectEvent = (e)=>{
        setIsShowModal(true);
        //console.log(e)
        setState({date : convertDate(e.start)});
    };
    // const createEvent = ()=>{
    //     const data = {allDay : false, start : state.startDate, end : state.endDate, title : 'hi siva', checkin : '20 : 00 : 00', checkOut : "20 : 00 : 00"};
    //     const newEventList = [...eventList];
    //     newEventList.push({...data});
    //     setEventList([...newEventList]);
    //     hideModal();
    // };

    const hideModal = ()=>{
        setIsShowModal(false);
    };
    const onDrillDown = (e)=>{
      const filterDates = [];
      eventList.map((event)=>{
       const dates = getBetweenDays(event.start, event.end);
       dates.map((date)=>{
        const currentDate = new Date(e);
        const givenDate = new Date(date);
          if(currentDate.getTime() == givenDate.getTime()){
            filterDates.push(event);
          }
       });
      });
      setState({date : e});
      setIsShowModal(true);
    };

    const getDates = (startDate, endDate) => {
      var dates = [];
  
      var currDate = moment(startDate).startOf('day');
      currDate = currDate.subtract(1,"days");
      var lastDate = moment(endDate).startOf('day');
  
      while(currDate.add(1, 'days').diff(lastDate) < 0) {
          //console.log(currDate.toDate());
          dates.push(currDate.clone().toDate());
      }
  
      return dates;
   }

   const onChangeDate = (date, type) => {
      const attendanceDateObj = {...attendanceDate};
      attendanceDateObj[type] = date;
      setAttendanceDate(attendanceDateObj);
   }
  
    const onSubmitDate = async () => { 
      const { startDate, endDate, employee} = attendanceDate;
      const formatStartDate = moment(startDate).format("YYYY_MM_DD");
      const formatEndDate = moment(endDate).format("YYYY_MM_DD");
      const today = new Date();
      if( startDate <= endDate && endDate <= today && moment(endDate).diff(moment(startDate), 'days')+1 < 32 && (roleName !== 'admin' || (employee && typeof employee.id === 'number'))) {
        try {
          const attendanceObj = { startDate: formatStartDate, endDate: formatEndDate};
          if(roleName !== 'employee') {
            attendanceObj.id = employee.id;
          }
          const results = await getAttendanceByDates(attendanceObj);
          const dateKeys = Object.keys(results.data);
          const attendanceDataGrid = dateKeys.map( (key) => {              
            const entryData = results.data[key];
            let startTime = "";
            const splitCheckin = [];
            let totalMinutes = 0;
            let addressObj = [];

            if(entryData  && entryData.length) {
              entryData.forEach( (data, index ) => {
                  const addressObjTemp = {
                    address: data.location_description || '',
                    type: data.checkin_type ===1 ? 'CheckIn' : 'CheckOut',
                    entry: moment(data.entry_at).format("HH:mm"),
                    remarks: data.remarks || '',
                  };
                  addressObj.push(addressObjTemp);
                  if(data.checkin_type ===1) {
                      startTime = data.entry_at;
                  }
                  else {                      
                      splitCheckin.push({ checkin: moment(startTime).format("HH:mm"), checkout: moment(data.entry_at).format("HH:mm")});
                      const duration =  moment.duration(moment(data.entry_at, "YYYY-MM-DD HH:mm:ss").diff(moment(startTime, "YYYY-MM-DD HH:mm:ss")));
                      totalMinutes += (duration._data.seconds/60) + duration._data.minutes + (duration._data.hours*60);
                      startTime = "";
                  }
              });
              if(startTime) {
                  splitCheckin.push({ checkin: moment(startTime).format("HH:mm") });
                  startTime = "";
              }
          }
          const dt = moment(key, 'YYYY_MM_DD');
          return {
            key: dt.format('YYYY_MM_DD'),
            addressDetail: addressObj,
            splitCheckin: showListCheckin(splitCheckin, dt, addressObj), totalHours: renderTotals(totalMinutes), 
            showNotes: showListRemarks(splitCheckin, dt, addressObj),
            date:  dt.format("DD-MM-YYYY"), day: dt.format('dddd'), dayInNumber: dt.day(),
            checkinStartEnd: splitCheckin.length ? `${splitCheckin[0].checkin} - ${ splitCheckin[splitCheckin.length-1] ? splitCheckin[splitCheckin.length-1].checkout || (splitCheckin[splitCheckin.length-2] ? splitCheckin[splitCheckin.length-2].checkout : '') || '' : ''}` :'-'
          }

          });
          setGridData(attendanceDataGrid);
        }
        catch(error) {
          setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        }
      }
      else {
        alert("Select valid inputs");
      }
    }

    const onSelectLocation = (addressDetail)=>{
      setLocations(addressDetail);
      setIsShowModal(true);
      setNotes([]);
    };

    const onSelectNotes = (addressDetail)=>{
      setNotes(addressDetail);
      setLocations([]);
      setIsShowModal(true);
      
    };

    const showListCheckin = (splitCheckin, dt, addressDetail="") => {

      if( splitCheckin.length ) {
        return <div>
          
          <ul>
          {splitCheckin.map((sc)=> {
          return <li>{(sc.checkin || "-") +" - "+ (sc.checkout || "did not checkout")}</li>
        })}
        </ul></div>
      }

      //console.log("day",dt.day)

      return dt.day() === 0 || dt.day() === 6 ? dt.format('dddd') : 'Not Present'
    }

    const showListRemarks = (splitCheckin, dt, addressDetail="") => {

      if( splitCheckin.length ) {
        return <div>
          <a href='#' className="btn-sm" onClick={()=> onSelectNotes(addressDetail)}>More</a></div>
      }

      //console.log("day",dt.day)

      return dt.day() === 0 || dt.day() === 6 ? dt.format('dddd') : 'Not Present'
    }

    const handleCreate = (inputValue) => {
      if(inputValue && inputValue.length > 1) {
          setIsLoading(true);
          getEmployeesListByKeyword(inputValue).then((results)=> {
              let employeesList = []
              setIsLoading(false);
              results.data.map((employee)=> {
                  employeesList.push({...employee, name : employee.first_name + ' ' + employee.last_name, value:employee.id, label: employee.first_name + ' ' + employee.last_name})
              })
              setEmployeeList(employeesList)
          }).catch(()=>{
            setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
          });
      }
  };

    const renderTotals = (totalDurationInMinutes) => {
      const hours = Math.floor(totalDurationInMinutes/60);
      const minutes = totalDurationInMinutes > 60 ? Math.floor(totalDurationInMinutes%60) : Math.floor(totalDurationInMinutes);
      return ("0" + hours).slice(-2)+":"+("0" + minutes).slice(-2);
    }

    const inputFields =  [
        { row : [{type : 'text', key : 'startDate'}] },
        { row : [{ type : 'text', key : 'endDate'}]},
        { row : [{type : 'text', key : 'eventName'}]}
    ];

    const actions = [
        {label : 'Close', className : 'btn-secondary', onClick : hideModal}
    ];

    const header = [
      { key : 'date', label : 'Date'},
      { key : 'splitCheckin', label: 'Check-in/Check-out'},
      { key : 'totalHours'},
      { key: 'showNotes', label: 'More'}
  ]

  const hideErrorMessage = () => {
    setServerErrorMessage('')
  }

  
  const onSelect = (selectedEmployee)=> { 
      if(selectedEmployee && selectedEmployee.id) { 
        const t = attendanceDate; t.employee = selectedEmployee; 
        setAttendanceDate(t)
      }  
    } 

    return (
        <Layout>
          <PageHeader title = 'Timesheet'/>
          {!!serverErrorMessage && <ErrorAlert close={hideErrorMessage}>
                {serverErrorMessage}
               </ErrorAlert>}
            <div className='col-10 offset-1'>
              <form id="attendanceForm">
                <div className="row mb-3 mt-3">
                {roleName !== 'employee' && <div className="col-md-4">
                    <div className='attendance-name'>
                      <label htmlFor="employeeName" className="form-label">Employee Name</label>
                        <AutoComplete
                              defaultValue={ attendanceDate.employee && attendanceDate.employee.id ? {label:attendanceDate.employee.name, value: attendanceDate.employee.id} : ""}
                              mandatory={false}
                              //value={attendanceDate.employee || []}
                              isLoading={isLoading}
                              name="employeelist"
                              optionText="Please enter valid employee name"
                              onSelect = {onSelect}
                              onChange = {handleCreate}
                              options={employeeList}/>
                    </div>
                  </div>}
                  <div className="col">
                      <div className='attendance-date-picker'>
                        <label htmlFor="startDate" className="form-label">Start Date</label>
                        <DatePicker id="startDate" maxDate={moment().toDate()} selected={attendanceDate.startDate} onChange={(d) => onChangeDate(d, "startDate")}/>
                      </div>
                      <div className='attendance-date-picker'>
                        <label htmlFor="endDate" className="form-label">End Date</label>
                        <DatePicker id="endDate" maxDate={moment().toDate()} selected={attendanceDate.endDate} onChange={(d) => onChangeDate(d, "endDate")}/>
                      </div>
                      <div className="btn-container">
                        <button type="button" className="btn btn-primary btn-sm me-3" onClick={onSubmitDate}>Submit</button>
                        <button type="button" className="btn btn-light btn-sm me-3" id="downloadBtn" onClick={()=>setGridData([])}>Clear</button>

                {!!gridData.length && <ExportToExcel excelData={gridData} fileName={attendanceDate.employee.first_name+"_"+attendanceDate.employee.id+"_Attendance_"} exportKeys={[ {key: "date", label:"Date"},{ key:"checkinStartEnd", label:"Checkin Detail"}, { key: "totalHours"} ]} />}
                      </div>
                  </div>
                </div>
              </form>
            </div>

            {!!gridData.length && <div className='col-10 offset-1 mt-5'><Table header = {header} body = { gridData } isHidePagination={true} /></div>}
            {isShowModal &&
                <Modal hideModal = {hideModal} title = {state.date} className = 'modal-lg'>
                  {notes.map((locationdetail)=>{
                    return (<div className='mb-15'>
                      <div>
                      <Badge className = 'bg-success f-s14 '>{locationdetail.entry}</Badge> {locationdetail.type} { locationdetail.remarks ? ': '+locationdetail.remarks : ''}
                      </div>
                      {locationdetail.address && <div><i className="bi bi-geo-alt-fill"></i>  {locationdetail.address}</div>}
                  </div>)
                  })}
                    <ModalFooter>
                        <Actions actions = {actions}/>
                    </ModalFooter>
                </Modal>          
            }
        </Layout>
    )

};

export default Attendance