const ErrorAlert=(props)=>{
    return(
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
            {props.children}
            { props.close && <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={props.close}>
                <span aria-hidden="true">&times;</span>
            </button>}
      </div>
    )
}
export default ErrorAlert;