//components
import { propTypes } from "qrcode.react";
import { splitPascalCase } from "../../Utils/splitPascalCase"
const Tap = (props)=> {

    const {tapMenu = [], activeTap = '' }  = props;

    const changeTap = (key)=> {
        if(props.changeTap) props.changeTap(key)
    }

    return(
        <>
        <ul className = 'nav nav-tabs d-flex text-center tab'>
            {tapMenu.map((item, index) => {
                const activeClass = activeTap == item.key ? 'active shadow-sm' : '' 
                const label = item.label || splitPascalCase(item.key) 
                return(
                    <li key = {index} className = 'nav-item col border-end'>
                        <a className = {'nav-link bg-light border-bottom h-100 ' + activeClass} href = '#' onClick = {() => changeTap(item.key)}>
                            <span className = 'me-2 d-block d-md-inline'>
                                <i className = {item.icon}></i>
                            </span>
                            <span>
                                {label}
                            </span>
                        </a>
                    </li>
                )
            })}
        </ul>
        <div className = 'shadow px-2 pt-2 pb-2'>
            {props.children}
        </div>
        </>
    )
}
export default Tap ;