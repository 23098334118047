import React, {useState, useRef, useEffect, createContext} from 'react';
import {
    Switch,
    Route,
    useHistory
  } from "react-router-dom";
import { adminNavItems } from '../constant/navItems' ;
import { getMenus } from '../services/menu.js';
import { logout } from '../Utils/login';
import { userDetailsContext } from "../context"
import { Capacitor } from '@capacitor/core';
import CheckInAdmin from '../Pages/CheckinAdmin.js';
import DashBoard from '../Pages/Dashboard.js';


const Logged = () => {

    const history = useHistory();


    const [ userDetails, setUserDetails ] = useState( {menu:[]} );




    useEffect(()=>{
        getMenus().then((result)=>{
            setUserDetails(result.data)
        }).catch((error)=>{
            if(error && error.response && error.response.status === 401) {
                logout()
                history.push('/Signup');
            }
            else{
                logout()
                history.push('/Signup');
            }
        })
    },[]);

    const platformName = Capacitor.getPlatform();
    const isRestrictPage = localStorage.getItem ( 'isRestrictPage' );

    const filteredMenuItems = adminNavItems.filter((item)=>{
        if((item.router === '/Checkin' || item.router === '/CheckinAdmin')) {
            const showCheckin = item.router === '/CheckinAdmin' ? 
            (platformName === 'android' || platformName === 'ios')  : 
            ((userDetails.gpsAllowed && (platformName === 'android' || platformName === 'ios')) || !userDetails.gpsAllowed);
            return item.page.includes(userDetails.role) && showCheckin;
        }
        else {
            return isRestrictPage === 'true' ? false : true;
        }
    });

    const filteredMenuFromUserDetails = userDetails.menu.filter((routerName)=>{
        if(routerName === 'checkin' || routerName === 'checkinAdmin') {
            const showCheckin = routerName === 'checkinAdmin' ? 
            (platformName === 'android' || platformName === 'ios')  : 
            (userDetails.gpsAllowed && (platformName === 'android' || platformName === 'ios') || !userDetails.gpsAllowed);
            return showCheckin;
        }
        return true;
    });

    const filteredUserDetails = {
        ...userDetails,
        menu: filteredMenuFromUserDetails
    }


    const renderRouter = () => {
        const pageRestrict = isRestrictPage === 'true' && filteredMenuFromUserDetails.includes('checkinAdmin');
        return <userDetailsContext.Provider value={filteredUserDetails}>
                <Switch>
                    {filteredMenuItems.map((page)=>{
                        return  <Route component={page.component} path={ page.router } />
                    })}
                    <Route path="*" component={pageRestrict ? CheckInAdmin : DashBoard} />      
                    {/* <Redirect exact from={ "/"} to={pageRestrict ? "/CheckinAdmin" : "/CheckinAdmin"} /> */}
                </Switch>
            </userDetailsContext.Provider>
    }


   return <React.Fragment>{renderRouter()}</React.Fragment>
}

export default Logged;