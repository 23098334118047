const Header=(props)=>{
    const {primaryHeading,heading,subHeading}=props;
        return(
            <>
               {primaryHeading && <div className="f-s20 text-primary f-w-bold ">{primaryHeading}</div>}
                <div className="mb-15">
                    {heading && <h6 className="f-s16 font-weight-bold mb-2 f-w-bold">{heading} </h6>}
                    {subHeading && <h6 className="f-s14 text-secondary">{subHeading }</h6>}
                </div>
            </>
        )
}
export default Header