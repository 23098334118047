import Button from '../Common/Button'
const Status = (props) => {

    // -- props values --
    // spinner is true --loader is show
    // success is true -- success simple and success message is show
    // successMessage -- show message to user

    const{  spinner = false, success = false, successMessage, onClick, buttonLabel = 'Close' } = props

    const onClickHandler = (event) => {
        if(onClick) onClick(event)
    }

    return(
        <div className = 'd-flex align-items-center flex-column '>
            <div className = {spinner ? 'd-block spinner-border text-success mb-3 pb-3 ' : 'd-none' } >
                <span className = "visually-hidden">Loading...</span>
            </div>
            <div className = {success ? 'd-block text-center' : 'd-none' } >
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#198754" className = "bi bi-check2-circle" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                </svg>
                <div className = 'text-success mt-2 mb-2'>
                    {successMessage}
                </div>
            </div>
            {props.children}
            <div className = {success ? 'd-block' : 'd-none'}>
                <Button onClick = {onClickHandler} className = 'btn-secondary'>
                    {buttonLabel}
                </Button>
            </div>

        </div>
    )
}
export default Status