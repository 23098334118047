import { useState, useEffect, useContext } from "react" ;
//components
import LayoutForm from '../Components/Layout' ; 
import PageHeader from '../Components/Common/PageHeader' ;
import Table from '../Components/Common/Table' ;
import Modal from '../Components/Common/Modal' ;
import ModalFooter from "../Components/Common/ModalFooter";
import Form from "../Components/Common/Form";
import Status from '../Components/Common/Status'
import Actions from "../Components/Common/Actions";
import Alert from '../Components/Common/Alert' ;
import { userDetailsContext } from "../context";
//utils
import {validation} from '../Utils/validation' ;
//services
import { getTeams as getTeamsService, updateTeam as updateTeamService, createTeam as createTeamService, deleteTeam as deleteTeamService }  from '../services/team' ;
import ErrorAlert from "../Components/Common/ErrorAlert";

const Team = (props) => {
    // const router = useRouter()
    // state for get team from server and send to table
    const [ teamList, setTeamList ] = useState ([])
    // state for create new team
    const [ team, setTeam ] = useState ( { newTeam : {}, errorMessage : {} } )
    // state show and hide modal
    const [ isShowModal, setIsShowModal ] = useState (false)
    // state for change modal add or edit
    const [ addEdit, setAddEdit ] = useState ()
    // state show and hide the alert
    const [ isShowAlert, setIsShowAlert ] = useState(false)
    // state for status add end edit team
    const [ status, setStatus ] = useState({ showStatus : false, spinner : false , success : false, successMessage : ''})

    const [paginationDetail, setPaginationDetail] = useState({itemsPerPage:10, page:1, total:10});
    const [isLoading, setIsLoading] = useState(false);
    const [ employeeList, setEmployeeList ] = useState([]);
    const [isLoader, setLoader] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState("");

    
    const roleName = useContext(userDetailsContext).role;


    // call getTeams api and send token and get teams details
    useEffect(()=>{
        setLoader(true);
        getTeamsService ().then((results)=>{
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            let teamList = [] ;
            result.map((t)=>{
                teamList.push({...t})
            })
            setLoader(false);
            setTeamList ( teamList );
            setPaginationDetail({itemsPerPage, page, total});

        }).catch(()=>{
            setLoader(false);
            // router.push('/Login')
            setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        });
    },[true]);


    const teamOnChange = ( event, key ) => {

        let newTeam = { ...team.newTeam }
        let errorMessage = { ...team.errorMessage }

        newTeam [key] = event.target.value
        errorMessage [key] = ''
        setTeam ( { ...team, newTeam, errorMessage } )

    }

    const showModal = ( addEdit , teamInfo ) =>{

        setStatus ( { showModal : true, status : false } )

        if ( addEdit == 'addTeam') {
            //set save and close button functions add or edit
            setAddEdit ( 'addTeam' )
        }
        else {
            let newTeam = {} ;
            newTeam.teamName = teamInfo.name ;
            newTeam.description = teamInfo.description ;
            newTeam.id = teamInfo.id
            setTeam ( { ...team, newTeam} );
            //set save and close button functions add or edit
            setAddEdit ('editTeam') 
        }
        setIsShowModal (true) ;   
    }

    const createTeam = ()=> {

        const validationResult = validation(inputFields, team.newTeam) ;
        const errorMessage = validationResult.errorMessage ;
        const isCreateTeam = validationResult.isValid ;
        setTeam ( { ...team, errorMessage } ) ;

        if ( isCreateTeam ){
            
            const data = { name : team.newTeam.teamName, description : team.newTeam.description, lead_id : 1  }

            setStatus ( { ...status, showStatus : true, spinner : true} )
            createTeamService (data).then ( (results)=> {

                let teamLists = [...teamList] ;
                const { teamName, description } = team.newTeam ;
                const newTeam =  { id : results.data.insertId, name : teamName, description : description, lead_id: 1  };
                setTeamList( [newTeam, ...teamLists] )

                setStatus ( { showStatus : true, spinner : false , success : true, successMessage : 'Team created successfully' } )

            }).catch((error)=>{
                setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
            })
        }      
    }
    
    const updateTeam = () =>{
        
        const validationResult = validation(inputFields, team.newTeam) ;
        const errorMessage = validationResult.errorMessage ;
        const isUpdateTeam = validationResult.isValid ;
        setTeam ( { ...team, errorMessage } )

        if ( isUpdateTeam ) {
            setStatus ( { ...status, showStatus : true, spinner : true} )
            const { id, teamName,description, lead } = team.newTeam
            const data = {  name : teamName, description : description, id :id, lead_id: 1  }
            updateTeamService (data).then ( (results)=>{

                let teamLists = [...teamList];
                let teamIndex = teamLists.findIndex ( teams => teams.id == team.newTeam.id )
                teamLists[teamIndex] = { ...teamLists[ teamIndex ], name : team.newTeam.teamName, description : team.newTeam.description };
               
                setTeamList ( [...teamLists] )

                setStatus ( { showStatus : true, spinner : false , success : true, successMessage : 'Team updated successfully' } )

            }).catch((error)=>{
                setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
            });
        }
    }



    const hideModal = ()=>{

        setTeam ({newTeam : {}, errorMessage : {}})
        setIsShowModal (false)

        setStatus ( {  showStatus : false, spinner : false , success : false, successMessage : ''} )
    }

    const showAlert = (teamInfo) => {

        let newTeam = { id : teamInfo.id }
        
        setTeam( { ...team, newTeam } )

        setIsShowAlert(true) 

    }

    const deleteTeam = () => {
        deleteTeamService (team.newTeam.id).then((results)=>{

            let teamLists = [ ...teamList ];
            const teamIndex = teamLists.findIndex ( (teams) => teams.id == team.newTeam.id )
            console.log (teamIndex)
            teamLists.splice( teamIndex, 1 )
            setTeamList ( [ ...teamLists ] )
            hideAlert ()
        }).catch (()=>{

        })

    }
   const hideAlert = () => {
        setIsShowAlert(false)
        setTeam ( { ...team, newTeam : {} } )
   }

   const onPaginationRoutePage = (page) => {
        const updatePagination = { ...paginationDetail, page};
        setPaginationDetail(updatePagination);
        getTeamsService(updatePagination).then((results) => {
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            const teamList = [];
            result.map((t)=>{
                teamList.push({...t})
            })
            setTeamList(teamList);
        }).catch()
    }

    const onChangeRowsPerPage = (itemsPerPage) => {
        const updatePagination = { ...paginationDetail, itemsPerPage, page:1};
        setPaginationDetail(updatePagination);
        getTeamsService(updatePagination).then((results) => {
            const employeeList = [];
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            const teamList = [];
            result.map((t)=>{
                teamList.push({...t})
            })
            setTeamList(teamList);
        }).catch()
    }

    //  team input Fields
    const inputFields = [
        { row : [ { type : 'text', key : 'teamName' } ]  },
        { row : [ { type : 'textarea', key : 'description', maxLength: '100' } ] }
    ]
    // Table Header and edit and delete buttons
    
    const actionsBtn = {
        key : "action", label : 'Actions',
        actions:  [
            {
                label : <i className="bi bi-pencil-fill"></i>,
                key : "edit",
                className : 'btn-primary',
                onClick : (teamInfo) => showModal ( 'editTeam', teamInfo )
            },
            {
                label : <i className="bi bi-trash-fill"></i>,
                key : "delete",
                className : 'btn-danger ml-10',
                onClick : (teamInfo) => showAlert ( teamInfo )
            }
        ]
    };

    const header = [
        { key : 'name'},
        { key : 'description'}
    ];

    if(roleName === 'admin') header.push(actionsBtn);



    // change modal add or editt
    const modalTitle = addEdit == 'addTeam' ? 'Create new team' : 'Edit team' ;

    const add = [
        {label : "Close", className : "btn-secondary", onClick : hideModal},
        {label : "Save", className : "btn-primary", onClick : createTeam }
    ] ;

    const edit = [ 
        {label : "Close", className : "btn-secondary", onClick : hideModal },
        {label : "Save", className : "btn-primary", onClick : updateTeam }
    ] ;

    const actions = addEdit == 'addTeam' ? add : edit ;

    // Delete actions
    const deleteActions = [
         { label : 'Delete', onClick : deleteTeam , className : 'btn-danger' },
         { label : 'Close', onClick : hideAlert , className : 'btn-secondary' }
    ]

    const hideErrorMessage = () => {
        setServerErrorMessage('')
    }

    return (
        <>
            <div>
                <LayoutForm>
                    {(roleName === 'employee' || roleName === "teamLead") && <PageHeader title = 'Manage Team' />}
                    {(roleName === 'admin' || roleName === "branchLead") && <PageHeader title = 'Manage Team' label = 'Add Team' onClick = { () => showModal ('addTeam')  }/>}
                    {!!serverErrorMessage && <ErrorAlert close={hideErrorMessage}>
                        {serverErrorMessage}
                    </ErrorAlert>}
                     { team && <Table isLoader={isLoader} header = {header} body = { teamList }  paginationDetail={paginationDetail} routePage={onPaginationRoutePage} onChangeRowsPerPage={onChangeRowsPerPage} /> }
                     {isShowModal && 
                        <Modal title = {modalTitle} hideModal = {hideModal}>
                                <div className = {!status.showStatus ? 'd-block' : 'd-none'}>
                                    <Form rows = {inputFields} className = 'col' value = { team.newTeam} errorMessage = {team.errorMessage} onChange = { teamOnChange } hideModal = {hideModal} />
                                    
                                    <ModalFooter>
                                        <Actions actions = {actions}/>
                                    </ModalFooter>
                                </div>
                                <div className = {status.showStatus ? 'd-block mb-3' : 'd-none'}>
                                    <Status spinner = {status.spinner} success = {status.success} successMessage = {status.successMessage} onClick = {hideModal} />
                                </div>
                            </Modal>        
                     }

                     { isShowAlert && <Alert label = 'Are you sure delete this item' actions = { deleteActions } hide = { hideAlert } />}

                </LayoutForm>
            </div>
        </>
    )
}
export default Team