const ShowImage = (props) => {
    const {src = '', alt = ''} = props;
    return(
        <>
            <div className = {'avatar border avatar-lg'}>
                <img alt = {alt} src = {src} />
            </div>    
        </>
    )
}

export default ShowImage