// components
import Input from '../Common/Input' ;
import Select from './Select';
import CheckBox from './CheckBox';
import Radio from './Radio';
import AutoComplete from './Autocomplete';
import MultiSelect from './MultiSelect';
import ErrorMessage from './ErrorMessage';
import FormControl from './FormControl';
import TimePicker from './Timepicker';
import TextArea from './TextArea';
import DatePicker from './DatePicker';
//utils
import { splitPascalCase } from '../../Utils/splitPascalCase'

const Form = (props) => {
    // const rows = [{row : [{name : 'dd', type : 'text', key : 'name' },{name : 'dwew', type : 'text', key : 'age' }]},{row : [{name : '34'}, {name : 'dfew2'}]}]
    // const actions = [{ label : 'close', className : 'btn-secondary'}, { label : 'Save', className : 'btn-primary'}]

    const { rows = [], value = {}, errorMessage = {}, readonly=false } = props
    
    const onChangeHandler = (event, key) =>{
        if(props.onChange) props.onChange(event, key)
    };
    const onBlurHandler =(event, key)=>{
        if(props.onBlurHandler) props.onBlurHandler(event, key)
    };
    // time picker handler
    const timeOnChange = (time, key) => {
        if(props.timeOnChange) props.timeOnChange(time, key);
    };

    const timeOnClick = (time, key) => {
        if(props.timeOnClick) props.timeOnClick(time, key);
    };

    const saveTime = (time, key)=>{
        if(props.saveTime) props.saveTime(time, key);
    };

    // autoComplete handler
    // const autoCompleteOnChange = (event, key) => {
    //     if(props.autoCompleteOnChange) props.autoCompleteOnChange(event, key)
    // }
    // const autoCompleteOnClick = (item, key) => {
    //     if(props.autoCompleteOnClick) props.autoCompleteOnClick( item, key )
    // }
    // const autoCompleteOnChange = (value, action, key)=>{
    //     if(props.autoCompleteOnChange1){
    //         props.autoCompleteOnChange1(value, action, key)
    //     }
    // };

    const autoCompleteOnSelect = (value, action, key)=>{
        if(props.autoCompleteOnSelect){
            props.autoCompleteOnSelect(value, action, key)
        }
    };

    const autoCompleteOnChange = (value, action, key)=>{
        if(props.autoCompleteOnChange){
            props.autoCompleteOnChange(value, action, key)
        }
    };

    const multiOnSelect = (value, action, key)=>{
        if(props.multiOnSelect){
            props.multiOnSelect(value, action, key);
        }
    };

    const multiOnChange = (value, action, key)=>{
        if(props.multiOnChange){
            props.multiOnChange(value, action, key)
        }
    };

    return(
        <>
        {rows && rows.length && rows.map((fields, rowIndex) => {
            return(
                <div key = {rowIndex} className = 'row align-items-center'>
                    {fields.row.map((col, colIndex) => {
                        const maxInputLength = col.maxLength ? col.maxLength : 
                        col.type == 'text' ? 30 :
                         col.type == 'password' ?  20 :
                            col.type == 'email' ? 254 : 20;
                        const label = col.label ? col.label : splitPascalCase( col.key || '' ) ;
                        return(
                            <div key = {colIndex} className = {col.sizeClass || 'col'}>
                                <FormControl>
                                    {/* { col.type && col.type != 'select' && col.type != 'checkbox' && col.type != 'radio' && col.type != 'autocomplete' && col.type != 'button' && col.type != 'addlistitem' && col.type != 'QRCode' && col.type != 'timepicker' && col.type != 'textarea' && col.type != 'autocomplete1' && */}
                                    {(col.type == 'text' || col.type == 'password' || col.type == 'date' || col.type =='time' || col.type =='email')  &&
                                        <Input type = { col.type} className = {col.className || ''} label = {label} value = {value[col.key] || ''} onChange = { (event) => onChangeHandler(event, col.key) } onBlurHandler = {(event)=> onBlurHandler(event, col.key)} mandatory = {col.isMandatory} name = {col.name || col.key} helpText = {col.helpText} 
                                        icon = {col.icon} isIConRight={col.isIConRight} onCLickIconHandler={col.onCLickIconHandler}
                                        maxLength={maxInputLength} />
                                    }
                                    { col.type == 'select' && <Select label = {label} tooltip={col.tooltip}
                                    options = {col.options} value = { value[col.key] } className = {col.className} name = { col.name || col.key } 
                                    onChange = {(event) => onChangeHandler(event, col.key)} disabled = {col.disabled} mandatory = {col.isMandatory}/>}

                                    { col.type == 'checkbox' && <CheckBox label = {label} name = {col.name || col.key} checkboxes = {col.checkboxes} onChange = { (event) => onChangeHandler(event, col.key) } disabled = {col.disabled} mandatory = {col.isMandatory}/>}

                                    { col.type == 'radio' && <Radio label = {label} radios = {col.radios} name = {col.name || col.key} currentValue = { value[col.key] } onChange = { (event) => onChangeHandler(event, col.key)} disabled = {col.disabled} mandatory = {col.isMandatory}/>}

                                    {/* { col.type == 'autocomplete' && <AutoComplete className =  {col.className}  label = {label} value = {value[col.key]} name = {col.name || col.key} suggestions = {col.suggestions} onChange = { (event) =>autoCompleteOnChange(event, col.key) } onClick = { (item) => autoCompleteOnClick(item, col.key)} disabled = {col.disabled} mandatory = {col.mandatory || true}/>} */}

                                    { col.type == 'timepicker' && <TimePicker mandatory={col.isMandatory} label = { label } onChange = { (time) => timeOnChange( time, col.key )} onClick = { (time) => timeOnClick( time, col.key )} saveTime = {(time)=>saveTime(time, col.key)}/>}

                                    { col.type == 'textarea' && <TextArea mandatory={col.isMandatory} maxLength="200" label = {label} value = { value[col.key] || ''} onChange ={ (event) => onChangeHandler ( event, col.key ) } name = {col.name || col.key}/> }

                                    {col.type == 'autocomplete' && 
                                        <AutoComplete mandatory={col.isMandatory} label = {label} 
                                        value = {value[col.key] || []} options = {col.options} 
                                        onSelect = {(currentValue, action)=>autoCompleteOnSelect(currentValue, action, col.key)}
                                         onChange = {(currentValue, action)=>autoCompleteOnChange(currentValue, action, col.key)} isMulti = {col.isMulti || false} handleCreate={col.handleCreate}
                                         isLoading={col.isLoading}
                                         isMultiple={col.isMultiple}
                                         isAllowCreate={col.isAllowCreate}
                                         optionText={col.optionText} />
                                    }

                                    {col.type == 'multiselect' &&
                                        <MultiSelect mandatory={col.isMandatory} label = {label} value = {value[col.key] || []} options = {col.options} onSelect = {(currentValue, action)=>multiOnSelect(currentValue, action, col.key)} onChange = {(currentValue, action)=>multiOnChange (currentValue, action, col.key)}/>
                                    }
                                    {col.type == 'datepicker' &&
                                        <DatePicker/>
                                    }
                                     <ErrorMessage label = { errorMessage[col.key] }/>
                            
                                </FormControl>
                            </div>
                        )
                    })}
                </div>
            )

        })}
        </>

    )
}
export default Form