import axios from "axios";
import { useState } from "react";
const BaseUrl = process.env.REACT_APP_BASEURL;

export const getLeavesList = (params)=> {
    // const url = BaseUrl + 'leaves' ;
    // const token = localStorage.getItem('token') || '' ;
    // return axios({
    //     method : 'get',
    //     url,
    //     headers : {
    //         Authorization : 'Bearer' + token
    //     }
    // })

    const url = BaseUrl+'leaves' 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'get',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        },
        params
    } )
}

export const createLeave = (data)=>{
    const url = BaseUrl+'leaves' 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'post',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        },
        data
    } )
}
export const updateLeave = (data) => {
    const url = BaseUrl+'leaves/'+data.id 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'put',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        },
        data
    } )
}
export const deleteLeave = (id)=> {
    const url = BaseUrl+'leaves/'+id 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'delete',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        }
    } )
}