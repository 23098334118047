const FormLabel = (props)=>{
    const { label, mandatory = true, className = '', onClickTooltip, tooltip } = props
    return(
        <>
        {label && 
           <label className = {'label mb-1' + className}>{label}{mandatory && <span className = 'text-danger mandatory-input'>* </span>}
           {tooltip &&  <i class="bi bi-question-circle-fill" onClick={onClickTooltip}></i>}
           </label>     
        }
        </>
    )
}
export default FormLabel ;