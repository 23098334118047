import axios from 'axios';
const BaseUrl = process.env.REACT_APP_BASEURL;

export const userNameValidate = (userName) => {
    const url = BaseUrl+'auth/check-username?user_name='+userName;
    return axios({
        method: 'get',
        url,
      });
}


export const getCountries = () => {
  const url = BaseUrl+'auth/countries';
  return axios({
      method: 'get',
      url,
    });
}

export const signup = (data) => {
  const url = BaseUrl + 'auth/signup';
  return axios({
      method: 'post',
      url,
      data
    });
}


export const activateAccount  = (data) => {
  const url = BaseUrl + 'auth/activate ';
  return axios({
      method: 'post',
      url,
      data
    });
}


export const login  = (data) => {
  const url = BaseUrl + 'auth/login';
  return axios({
      method: 'post',
      url,
      data
    });
}

export const forgotPassword  = (data) => {
  const url = BaseUrl + 'auth/forgot-password';
  return axios({
      method: 'post',
      url,
      data
    });
}


